import { post, get, del } from "@/utils/request";
import type { AxiosRequestConfig } from "axios";
const commonUrl = process.env.REACT_APP_URL;

/**
 * 根据名称获取字典详情列表
 * @param name 名称
 */
export function getDictListByName(name: string) {
  return get(`${commonUrl}/dict/getList/${name}`);
}

/**
 * 获取
 */
export function list(config: AxiosRequestConfig) {
  return get(`${commonUrl}/dict/page`, config);
}

/**
 * 新增
 */
export function insert(config: AxiosRequestConfig) {
  return update(config);
}
/**
 * 更新
 */
export function update(config: AxiosRequestConfig) {
  return post(`${commonUrl}/dict`, config);
}

/**
 * 删除
 */
export function batchDel(ids: string) {
  return del(`${commonUrl}/dict/${ids}`);
}

/**
 * 获取static列表
 */
export function staticList(typeId: string) {
  return get(`${commonUrl}/dict/static/${typeId}`);
}

/**
 * 保存static
 */
export function staticSave(config: AxiosRequestConfig) {
  return post(`${commonUrl}/dict/static`, config);
}

/**
 * 保存static
 */
export function staticDel(ids: string) {
  return del(`${commonUrl}/dict/static/${ids}`);
}

/**
 * 保存动态字典值
 * @param config
 */
export function dynamicSave(config: AxiosRequestConfig) {
  return post(`${commonUrl}/dict/dyna`, config);
}

/**
 * 获取动态字典值
 * @param typeId
 */
export function dynamicList(typeId: number) {
  return get(`${commonUrl}/dict/dyna/${typeId}`);
}

/**
 * 根据模型id获取模型字段名称
 * @param modelId
 */
export function getModelProperties(modelId: string) {
  return get(`${commonUrl}/data/property/model/${modelId}`);
}
/**
 * 同步至项目
 */
export function syncToProject(config: AxiosRequestConfig) {
  return post(`${commonUrl}/dict/sync`, config);
}
/**
 * 字典下拉框
 */
export function dictOptions(projectId: number | string) {
  return get(`${commonUrl}/dict/list/${projectId}`);
}
/**
 * 组件数据字典联动查询
 */
export function interactionQueryWithChange(config: AxiosRequestConfig) {
  return post(`${commonUrl}/dict/getListByParams`, config);
}
