import {get, post} from "@/utils/request";

const commonUrl = process.env.REACT_APP_URL;

/**
 * @Author wangyubo
 * @Date 2023/12/21
 * @Path src\service\news.ts
 * @IDE news.ts
 * @Desc 新闻相关接口
 **/


// <editor-fold desc="首页新闻" defaultstate="collapsed">
export interface NewsHomeParams {
  current: number;
  factorPortal: number;
  home: number;
  hot: number;
  newsCategoryId: number;
  operatePortal: number;
  publishEndTime: string;
  publishStartTime: string;
  search: string;
  size: number;
  source: string;
  /** 1发布  0 未发布  */
  status: number[];
  third: number;
  title: string;
}

/**
 * @desc 首页新闻-getNewsHome-返回参数
 */
export interface GetNewsHomeRes {
  category: string;
  content: string;
  cover: string;
  createTime: string;
  createUser: string;
  factorHome: boolean;
  factorHot: boolean;
  factorId: number;
  id: string;
  newsCategoryId: string;
  operateHome: boolean;
  operateHot: boolean;
  operateId: number;
  overHead: any[];
  portals: any[];
  publishTime: string;
  publisher: string;
  source: string;
  status: number;
  summary: string;
  title: string;
  updateTime: string;
  updateUser: string;
  viewCount: number;
  coverImg: string;
}

/**
 * @desc 首页新闻
 * @param data
 */
export function getNewsHome(data: Partial<NewsHomeParams>) {
  return post<GetNewsHomeRes[]>(`${commonUrl}/yy-article/home`, {data})
}

// </editor-fold>


// <editor-fold desc="新闻浏览页" defaultstate="collapsed">

/**
 * @desc 新闻浏览页-getNewsForBrowse-请求接口
 */
export interface GetNewsForBrowseParams {
  current: number;
  /**  要素门户 勾选为1  */
  factorPortal: number;
  /**  首页 勾选为1  */
  home: number;
  /**  热门 勾选为1  */
  hot: number;
  /**  栏目id  */
  newsCategoryId: number | string;
  /**  运营门户 勾选为1  */
  operatePortal: number;
  /**  发布截止时间  */
  publishEndTime: string;
  /**  发布开始时间  */
  publishStartTime: string;
  /**  首页全局搜索项  */
  search: string;
  /** size  */
  size: number;
  /**  来源  */
  source: string;
  /**  发布状态  */
  status: number;
  /**  第三项(否) 勾选为1  */
  third: number;
  /** 活动名称  */
  title: string;
}

/**
 * @desc 新闻浏览页-getNewsForBrowse-返回参数
 */
export interface GetNewsForBrowseRes {
  current: number;
  pages: number;
  records: GetNewsForBrowseResRecords[];
  size: number;
  total: number;
}

export interface GetNewsForBrowseResRecords {
  category: string;
  content: string;
  cover: string;
  createTime: string;
  createUser: string;
  factorHome: boolean;
  factorHot: boolean;
  factorId: number;
  id: number;
  newsCategoryId: string;
  operateHome: boolean;
  operateHot: boolean;
  operateId: number;
  overHead: any[];
  portals: any[];
  publishTime: string;
  publisher: string;
  source: string;
  status: number;
  summary: string;
  title: string;
  updateTime: string;
  updateUser: string;
  viewCount: number;
}

/**
 * @desc 新闻浏览页
 * @param data
 */
export function getNewsForBrowse(data: Partial<GetNewsForBrowseParams>) {
  return post<GetNewsForBrowseRes>(`${commonUrl}/yy-article/pagForBrowse`, {data: {factorPortal: 1, ...data}})
}

/**
 * @desc 获取 热门新闻 （新接口）
 */
export function getHotNews() {
  return post(`${commonUrl}/yy-article/hotNews`, {
    data: {
      type: "1"
    }
  })
}

/**
 * @desc 获取 首页新闻 （新接口）
 */
export function getHomeNews() {
  return post(`${commonUrl}/yy-article/homeNews`, {
    data: {
      type: "1"
    }
  })
}


// </editor-fold>

// <editor-fold desc="新闻详情" defaultstate="collapsed">

export interface GetNewsDetailRes {
  attachmentList: any[];
  id: string;
  title: string;
  newsCategoryId: string;
  category: string;
  summary: string;
  content: string;
  source: string;
  status: number;
  viewCount: number;
  publisher?: any;
  publishTime?: any;
  createUser: string;
  createTime: string;
  updateUser: string;
  updateTime: string;
  cover: string;
  preId: string;
  preTitle: string;
  nextId: string;
  nextTitle: string;
}

/** 获取普通新闻详情  */
export function getNewsDetail(id: string, data: any) {
  return post<GetNewsDetailRes>(`${commonUrl}/yy-article/browse/view/${id}`, {
    data: {
      factorPortal: 1,
      size: -1,
      current: 1,
      ...data
    }
  })
}

/** 获取首页详情  */
export function getHomeNewsDetail(id: string) {
  return post<GetNewsDetailRes>(`${commonUrl}/yy-article/home/view/${id}`, {
    data: {
      type: "1"
    }
  })
}

/** 获取热门详情  */
export function getHotNewsDetail(id: string, data: any) {
  return post<GetNewsDetailRes>(`${commonUrl}/yy-article/hot/view/${id}`, {
    data: {
      type: "1"
    }
  })
}

// </editor-fold>

// <editor-fold desc="新闻栏目列表" defaultstate="collapsed">

/**
 * @desc 获取新闻分类-getNewsCategory-请求参数
 */
export interface GetNewsCategoryParams {
  /** 是否异步（默认false) */
  async: boolean;
  /** 能否编辑 是1 否0  */
  editable: number;
  id: number;
  /**  栏目名称  */
  name: string;
  /** 父级id  */
  parentId: number;
  /** 父级ids /分隔  */
  parentIds: string;
  /** 搜索名  */
  searchName: string;
  icon: boolean;
  separator: string;
  weight: number;
}

/**
 * @desc 获取新闻分类-getNewsCategory-返回参数
 */
export interface GetNewsCategoryRes {
  branchDefaultIcon: string;
  children: GetNewsCategoryResChildren[];
  detail: GetNewsCategoryResDetail;
  disabled: boolean;
  fieldType: string;
  iconSkin: string;
  id: number | string;
  leaf: boolean;
  leafDefaultIcon: string;
  length: number;
  name: string;
  nocheck: boolean;
  open: boolean;
  pId: number;
  parent: boolean;
  parentIds: string;
  primaryKey: boolean;
  root: boolean;
  rootDefaultIcon: string;
}

export interface GetNewsCategoryResChildrenChildren {

}

export interface GetNewsCategoryResChildrenDetail {

}

export interface GetNewsCategoryResChildren {
  branchDefaultIcon: string;
  children: GetNewsCategoryResChildrenChildren[];
  detail: GetNewsCategoryResChildrenDetail;
  disabled: boolean;
  fieldType: string;
  iconSkin: string;
  id: number;
  leaf: boolean;
  leafDefaultIcon: string;
  length: number;
  name: string;
  nocheck: boolean;
  open: boolean;
  pId: number;
  parent: boolean;
  parentIds: string;
  primaryKey: boolean;
  root: boolean;
  rootDefaultIcon: string;
}

export interface GetNewsCategoryResDetail {

}

/**
 * @desc 获取新闻分类
 * @param data
 */
export function getNewsCategory(data: Partial<GetNewsCategoryParams>) {
  return get<GetNewsCategoryRes[]>(`${commonUrl}/yy-news-category/tree`, {
    params: {
      ...data,
      type: "1"
    }
  })
}

// </editor-fold>