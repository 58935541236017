class StoreService {
  save(key: string, data: any) {
    sessionStorage.setItem(
      key,
      typeof data === "string" ? data : JSON.stringify(data)
    );
  }

  getItem(key: string): string | null {
    return sessionStorage.getItem(key);
  }

  saveBaseInfo(data: any) {
    this.save("baseInfo", data);
  }

  getBaseInfo(): any {
    return JSON.parse(sessionStorage.getItem("baseInfo")!)
  }

  saveAppList(data: any) {
    this.save("appList", data);
  }

  getToken(): string {
    return this.getItem("token") as string;
  }

  getBaseUrl(): string {
    const identity = this.getItem("identity");
    const appList = JSON.parse(this.getItem("appList") || "[]");
    const app = appList.find((item: any) => item.source === identity);
    return app?.appUrl;
  }
}
const storeService = new StoreService();
export default storeService;
