import {lazy} from 'react';
import {DcRoute} from '@/interface/DcRouter';
import {menuList} from './menu';

export const backendRouter: DcRoute[] = [
  {
    path: '/backend',
    component: lazy(() => import('@/views/backend/layout')),
    children: [
      {
        path: '/backend',
        redirect: true,
        to: '/backend/workplat',
      },
      {
        name: '工作台',
        path: '/backend/workplat',
        meta: {title: '工作台'},
        component: lazy(
          () => import('@/views/backend/workplat')
        ),
      },
      {
        path: '',
        meta: {title: '账号管理'},
        children: [
          {
            name: '基本信息',
            path: '/backend/userInfo',
            meta: {title: '基本信息'},
            component: lazy(
              () => import('@/views/backend/userInfo')
            ),
          },
          {
            name: '准入认证',
            path: '/backend/admissionCert',
            meta: {title: '准入认证'},
            component: lazy(
              () => import('@/views/backend/admissionCert/List')
            ),
          },
          {
            name: '准入认证申请',
            path: '/backend/admissionCert/add',
            meta: {title: '准入认证申请'},
            component: lazy(
              () =>
                import('@/views/backend/admissionCert/AddCert')
            ),
          },
          {
            name: '准入认证申请详情',
            path: '/backend/admissionCert/detail',
            meta: {title: '准入认证申请详情'},
            component: lazy(
              () => import('@/views/backend/admissionCert/Detail')
            ),
          },
          {
            name: '历史签署协议详情',
            path: '/backend/admissionCert/signDetail',
            meta: {title: '历史签署协议详情'},
            component: lazy(
              () =>
                import(
                  '@/views/backend/admissionCert/HistorySignDetail'
                  )
            ),
          },
          {
            name: "白名单设置",
            path: "/backend/writeList",
            meta: {title: '白名单设置'},
            component: lazy(
              () => import('@/views/backend/userInfo/whiteList')
            ),
          },
          {
            name: "Accesskey管理",
            path: "/backend/accesskey",
            meta: {title: 'Accesskey管理'},
            component: lazy(
              () => import('@/views/backend/userInfo/accesskey')
            ),
          }, {
            name: "主子账号管理",
            path: "/backend/accountMgt",
            meta: {title: '主子账号管理'},
            component: lazy(
              () => import('@/views/backend/userInfo/accountMgt')
            ),
          },
        ],
      },
      {
        path: '',
        meta: {title: '数聚大厅'},
        children: [
          {
            name: '数据需求',
            path: '/backend/dataDemand',
            meta: {title: '数据需求'},
            component: lazy(
              () => import('@/views/backend/dataDemand')
            ),
          },
          {
            name: '数据需求详情',
            path: '/backend/dataDemand/detail',
            meta: {title: '数据需求详情'},
            component: lazy(
              () => import('@/views/backend/dataDemand/Detail')
            ),
          },
        ],
      },
      {
        path: '/backend/ServiceSupport',
        meta: {title: '服务支持'},
        children: [
          {
            name: '留言咨询',
            path: '/backend/ServiceSupport/ProblemFeedback',
            meta: {title: '留言咨询'},
            component: lazy(
              () =>
                import(
                  '@/views/backend/ServiceSupport/ProblemFeedback'
                  )
            ),
          },
          {
            name: '咨询详情',
            path: '/backend/ServiceSupport/ProblemFeedback/detail',
            meta: {title: '咨询详情'},
            component: lazy(
              () =>
                import(
                  '@/views/backend/ServiceSupport/ProblemFeedback/Detail'
                  )
            ),
          },
          {
            name: '问题反馈',
            path: '/backend/ServiceSupport/Issue',
            meta: {title: '问题反馈'},
            component: lazy(
              () =>
                import(
                  '@/views/backend/ServiceSupport/Issue'
                  )
            ),
          },
          {
            name: '问题详情',
            path: '/backend/ServiceSupport/Issue/detail',
            meta: {title: '问题详情'},
            component: lazy(
              () =>
                import(
                  '@/views/backend/ServiceSupport/Issue/Detail'
                  )
            ),
          },
        ],
      },
      {
        path: '/backend/activitiesManagement',
        meta: {title: '活动管理'},
        children: [
          ...(process.env.REACT_APP_HIDE_MATCH === "true" ? [] : [
            {
              name: '创新大赛',
              path: '/backend/activitiesManagement/innovativeMatch',
              meta: {title: '创新大赛'},
              component: lazy(
                () =>
                  import(
                    '@/views/backend/activitiesManagement/innovativeMatch'
                    )
              ),
            },
          ]),
          {
            name: '生态活动',
            path: '/backend/activitiesManagement/zoneActivity',
            meta: {title: '生态活动'},
            component: lazy(
              () =>
                import(
                  '@/views/backend/activitiesManagement/zoneActivity'
                  )
            ),
          },
        ],
      },
      {
        path: '',
        meta: {title: '消息中心'},
        children: [
          {
            name: '消息中心',
            path: '/backend/msgCenter',
            meta: {title: '消息中心'},
            component: lazy(
              () => import('@/views/backend/msgCenter')
            ),
          },
          {
            name: '消息详情',
            path: '/backend/msgCenter/detail',
            meta: {title: '消息详情'},
            component: lazy(
              () =>
                import(
                  '@/views/backend/msgCenter/detail'
                  )
            )
          }]
      },
      {
        path: '/backend/activitiesManagement',
        meta: {title: '消费中心'},
        children: [
          {
            name: '账户管理',
            path: '/backend/consumeCenter/accountManage',
            meta: {title: '账户管理'},
            component: lazy(
              () =>
                import(
                  '@/views/backend/ConsumeCenter/AccountManage'
                  )
            ),
          },
          {
            name: '账单管理',
            path: '/backend/consumeCenter/billManage',
            meta: {title: '账单管理'},
            component: lazy(
              () =>
                import(
                  '@/views/backend/ConsumeCenter/BillManage'
                  )
            ),
          },
          {
            name: '账单详情-手动 自动',
            path: '/backend/consumeCenter/billManage/details/createBill',
            meta: {title: '账单详情-手动 自动'},
            component: lazy(
              () =>
                import(
                  '@/views/backend/ConsumeCenter/BillManage/details/createBill'
                  )
            ),
          },
          {
            name: '账单详情-补账',
            path: '/backend/consumeCenter/billManage/details/supplementBill',
            meta: {title: '账单详情-补账'},
            component: lazy(
              () =>
                import(
                  '@/views/backend/ConsumeCenter/BillManage/details/supplementBill'
                  )
            ),
          },
          {
            name: '账单列表-确认账单',
            path: '/backend/consumeCenter/billManage/billConfirm',
            meta: {title: '账单详情-补账'},
            component: lazy(
              () =>
                import(
                  '@/views/backend/ConsumeCenter/BillManage/confirmBill'
                  )
            ),
          },

          //   合同管理
          {
            path: "/backend/ContractManage",
            meta: {title: "合同管理"},
            component: lazy(
              () =>
                import(
                  "@/views/backend/consumptionCcenter/ContractManage/Contract"
                  )
            ),
          },
          {
            path: "/backend/ContractManage/detail",
            meta: {title: " 合同详情"},
            component: lazy(
              () =>
                import(
                  "@/views/backend/consumptionCcenter/ContractManage/ContractDetail"
                  )
            ),
          },
          {
            path: "/backend/ContractManage/detail/edits",
            meta: {title: " 编辑合同"},
            component: lazy(
              () =>
                import(
                  "@/views/backend/consumptionCcenter/ContractManage/Add"
                  )
            ),
          },
          {
            path: "/backend/ContractManage/detail/changes",
            meta: {title: " 变更合同"},
            component: lazy(
              () =>
                import(
                  "@/views/backend/consumptionCcenter/ContractManage/ChangeContract"
                  )
            ),
          },
          // 流水明细
          {
            path: "/backend/TransactionDetails",
            meta: {title: "流水明细"},
            component: lazy(
              () =>
                import(
                  "@/views/backend/consumptionCcenter/TransactionDetails/TransactionDetails"
                  )
            ),
          },
        ],
      },
      {
        name: '产品中心',
        meta: {title: '消息中心'},
        path: '',
        children: [
          {
            name: '授权抽查任务',
            path: '/backend/productCenter/authTask',
            meta: {title: '授权抽查任务'},
            component: lazy(
              () =>
                import(
                  '@/views/backend/productCenter/spotCheckTask'
                  )
            ),
          },
          {
            name: '授权抽查详情',
            path: '/backend/productCenter/authTask/detail',
            meta: {title: '授权抽查详情'},
            component: lazy(
              () =>
                import(
                  '@/views/backend/productCenter/spotCheckTask/detailPage'
                  )
            ),
          },
          {
            name: '上传授权文件',
            path: '/backend/productCenter/authTask/submit',
            meta: {title: '上传授权文件'},
            component: lazy(
              () =>
                import(
                  '@/views/backend/productCenter/authTask/submitPage'
                  )
            ),
          },
          {
            name: '已购产品',
            path: '/backend/productCenter/purchasedProduct',
            meta: {title: '已购产品'},
            component: lazy(
              () => import('@/views/backend/productCenter/purchasedProduct')
            ),
          },
          {
            name: 'API在线试用',
            path: '/backend/productCenter/apiTryout',
            meta: {title: 'API在线试用'},
            component: lazy(
              () =>
                import(
                  '@/views/backend/productCenter/apiTryout'
                  )
            )
          },
          {
            name: '已购产品详情',
            path: '/backend/productCenter/purchasedProduct/detail',
            meta: {title: '已购产品详情'},
            component: lazy(
              () => import('@/views/backend/productCenter/productDetail')
            ),
          },
        ]
      },
      {
        path: '',
        meta: {title: '日志管理'},
        children: [
          {
            name: '调用日志',
            path: '/backend/productLog',
            meta: {title: '调用日志'},
            component: lazy(
              () => import('@/views/backend/productLog')
            ),
          },
          {
            name: '日志详情',
            path: '/backend/productLog/detail',
            meta: {title: '日志详情'},
            component: lazy(
              () =>
                import(
                  '@/views/backend/productLog/detail'
                  )
            )
          }]
      },
    ],
  },
];

export const routes: DcRoute[] = [
  {
    path: '/',
    redirect: true,
    to: '/welcome',
  },
  {
    path: '/',
    component: lazy(() => import('@/views/layout')),
    children: [
      {
        name: '线下沙龙详情',
        path: '/zone/offlinesalonsDeatil',
        meta: {title: '线下沙龙'},
        component: lazy(
          () => import('@/views/EventZone/OfflineSalons/Detail')
        ),
        isMenu: false,
      },
      {
        name: '数据需求详情',
        path: '/lobbies/demand/detail',
        meta: {title: '数据需求详情'},
        component: lazy(
          () => import('@/views/DataLobbies/DemandDetail')
        ),
      },
      {
        path: '/news/detail/:id',
        meta: {title: '新闻详情'},
        component: lazy(() => import('@/views/News/Detail')),
      },
      {
        path: '/scenario/detail/:id',
        meta: {title: '数据场景服务详情'},
        component: lazy(
          () => import('@/views/DataScenario/DataSet/Detail')
        ),
      },
      ...menuList,
    ],
  },
  ...backendRouter,
  {
    path: '/welcome',
    meta: {title: '欢迎页'},
    component: lazy(() => import('@/views/welcome')),
  },
  {
    path: '/login',
    meta: {title: '登陆'},
    component: lazy(() => import('@/views/login')),
  },
  {
    path: '/register',
    meta: {title: '注册'},
    component: lazy(() => import('@/views/register')),
  },
  {
    path: '/registerSuccess',
    meta: {title: '注册成功'},
    component: lazy(() => import('@/views/registerSuccess')),
  },
  {
    path: '/forgetPassword',
    meta: {title: '忘记密码'},
    component: lazy(() => import('@/views/forgetPassword')),
  },
  {
    path: '/404',
    meta: {title: '404'},
    component: lazy(() => import('@/views/404')),
  },
];
