import React, {Suspense, useEffect} from "react";
import {Navigate, Route, Routes} from "react-router-dom";
import "./App.css";
import {DcRoute} from "@/interface/DcRouter";
import DLayout from "./views/layout";
import RouteWrapper from "./components/RouteWrapper";
import {routes} from "./router/route";
import {useRequest} from "ahooks";
import {getGlobalNav, getGlobalSetting} from "@/service/common";
import {setFavicon} from "@/utils";

export default function App() {

  /** 获取 导航菜单 存到本地  */
  useRequest(getGlobalNav, {
    onSuccess: (res) => {
      sessionStorage.setItem("NAV_LIST", JSON.stringify(res))
    }
  })
  /** 获取 全局设置 存到本地  */
  useRequest(getGlobalSetting, {
    onSuccess: (res) => {
      sessionStorage.setItem("Global_SETTING", JSON.stringify(res))
      setFavicon(res?.websiteIcon);
      document.title= res?.websiteName
    }
  })


  useEffect(() => {
    console.log("APP加载~~~~");
    var mobile = require("is-mobile");

    function autoSetRootFontSize() {
      // PC端默认1920的设计稿
      let ww = Math.min(
        window.screen.width,
        document.documentElement.getBoundingClientRect().width
      );
      // console.log("WW", ww);
      let zoomLevel = window.devicePixelRatio * 100;
      // console.log(zoomLevel, mobile());
      // 如果是移动设备，修改初始缩放
      let iphone = window.navigator.userAgent.indexOf("iPhone") > -1;
      if (mobile() && !iphone) {
        document.documentElement.style.fontSize = "1rem";
        (document as any).documentElement.style.zoom = ww / 1920;
      } else {
        ww = Math.max(1360, ww);
        document.documentElement.style.fontSize = (ww * 16) / 1920 + "px";
        window.addEventListener("resize", autoSetRootFontSize);
      }
    }

    if (!mobile()) {
      document.body.setAttribute("class", "keep-px-pcbody");
    }
    autoSetRootFontSize();
    return () => {
      window.removeEventListener("resize", autoSetRootFontSize);
    };
  });

  function getRoutes(routeList: DcRoute[]): React.ReactNode[] {
    return routeList.map((item) => {
      if (item.redirect) {
        return (
          <Route
            key={item.path}
            path={item.path}
            element={<Navigate to={item.to || ""}/>}
          />
        );
      }
      if (item.children) {
        if (item.component) {
          return (
            <Route
              key={item.path}
              path={item.path}
              element={
                <Suspense fallback={"加载中..."}>
                  <RouteWrapper path={item.path} wrappers={item.wrappers}>
                    <item.component/>
                  </RouteWrapper>
                </Suspense>
              }
            >
              {getRoutes(item.children)}
            </Route>
          );
        } else {
          return getRoutes(item.children);
        }
      }
      return (
        <Route
          key={item.path}
          path={item.path}
          element={
            <Suspense fallback={"加载中..."}>
              <RouteWrapper path={item.path} wrappers={item.wrappers}>
                <item.component/>
              </RouteWrapper>
            </Suspense>
          }
        />
      );
    });
  }

  return (
    <Routes>
      {getRoutes(routes)}
      <Route path="/sys/*" element={<DLayout/>}/>
    </Routes>
  );
}
