export enum Gender {
  女,
  男
}
// 认证角色
export enum PartnerTypeEnums {
  "潜客" = -1,
  "数据使用方" = 0,
  // "数据渠道方" = 1,
  "数据提供方" = 2,
  "数据参与方" = 3,
  "产品共创方" = 4,
  "产品展销方" = 5,
}