import {Descriptions, Modal} from "antd";
import logo from "@/assets/images/logo.png";
import beianImg from "@/assets/images/ga.png";
import {menuList} from "@/router/menu";
import {useNavigate} from "react-router-dom";

import styles from "./index.module.less";
import {useModalMap} from "../../../components/ModalMap";
import {MINIIO_PREVIEW_PATH} from "@/service/common";
import {searchConfigNameOrPath, setWeightField, sortNestedArrayByWeight} from "@/views/layout/commonMenus";

export default function MFooter() {
  const navigate = useNavigate();
  const globalSetting = JSON.parse(sessionStorage.getItem("Global_SETTING") || '{}')
  const navList = JSON.parse(sessionStorage.getItem("NAV_LIST") || "[]")

  // 页面跳转
  const toPage = (url: string, params?: any) => {
    navigate(url, params && {state: params});
  };

  /** 打开地图  */
  const {jsx: viewMap, setOpen} = useModalMap({
    value: globalSetting?.subjectAddress,
  });

  const infoData = {
    operation: "成都数据集团股份有限公司",
    phone: "028-61556586",
    email: "sdd@qy.com",
    address: "成都市武侯区天府大道北段966号天府国际金融中心9号楼",
  };

  const openModal = () => {
    Modal.info({
      className: styles.modalWrapper,
      // title: '网站声明',
      icon: null,
      width: 900,
      content: (
        <div style={{maxHeight: 650, overflowY: "auto"}}>
          <h2 style={{textAlign: "center"}}>网站声明</h2>
          <p style={{textAlign: "center"}}>协议生效时间：2024年02月18日</p>
          <p>
            蓉数公园·数据要素流通服务平台网站（http://www.dataxpark.com）由成都数据集团股份有限公司建设，成都市智慧蓉城研究院有限公司运营管理（以下简称网站运营方）。本声明阐述之条款适用于所有访问本网站的用户及与本网站产生法律关系的企业或单位。
          </p>
          <p>1.权利声明</p>
          <p>
            1.1除非本网站另行声明，本网站上所生成、制作、推出的所有产品、技术、软件、程序、数据及相关内容及信息（包括文字、图片、音视频、网页版面、图表）等合法权益归本网站或原始内容及数据提供方所有。
          </p>
          <p>
            1.2为维护本网站的正常运行秩序及效率，未经本网站许可，任何人不得自行、授权或协助第三方对本网站及其系统进行地址扫描、网络端口扫描、操作系统探测等扫描及/或探测，或以包括通过机器人、蜘蛛等程序或设备监视、复制、传播、展示、镜像、上载、下载等方式擅自获取、使用本网站内容。
          </p>
          <p>
            1.3本网站上的商标性文字及/或图形标识，服务运营商字号名称等均为本网站注册商标等权利，如有宣传、展示等其他使用需要，须取得本网站书面授权。
          </p>
          <p>
            1.4.http://www.dataxpark.com网站域名及“蓉数公园·数据要素流通服务平台”中文名称为本网站所有，任何其他网站不得以上述名称命名。
          </p>
          <p>
            1.5未经本网站许可，任何人擅自使用本声明1.1、1.2、1.3、1.4项所涉内容，均会侵犯本网站的合法权益，我们将会依法追究侵权者的法律责任。
          </p>
          <p>2.知识产权保护</p>
          <p>
            本网站尊重知识产权，坚决反对并打击任何侵犯知识产权的行为。知识产权权利人若发现本网站上的内容（包括但不限于数据提供方提供的任何信息）侵犯其合法权益，可以通过本网站设置的投诉通道进行投诉，本网站将在收到知识产权权利人通知后依据相应的法律法规以及网站规则及时处理。
          </p>
          <p>3.通知及投诉途径</p>
          <p>
            如您认为本网站上存有侵权、虚假信息、错误信息或其他任何问题，请及时联系我们，我们将在第一时间删除或更正。
          </p>
          <p>{`联系方式：${globalSetting?.subjectPhone} 联系邮箱：${globalSetting?.subjectEmail}`}</p>
          <p>2024年02月18日</p>
        </div>
      ),
      okText: "关闭",
      okButtonProps: {
        type: "default",
        shape: "round",
      },
      onOk() {
      },
    });
  };

  /** 匹配最后一个点及其后面的字符，然后替换为空  */
  function removeExtension(filename) {
    return filename.replace(/\.[^.]+$/, "")
  }

  function getItems(arr: any) {
    if (!arr) return;
    return arr?.filter((menu) => !menu.hidden && menu.name !== "首页").map(menu => {
      return <div className={styles.menu} key={menu.path}>
        <div className={styles.menuName}>{searchConfigNameOrPath(menu?.id, navList, "name", menu.name)}</div>
        <div className={styles.submenuWrap}>
          {(menu.children || [menu])
            ?.filter((menu) => !menu.hidden)
            ?.map((submenu) => (
              <div
                key={submenu.path}
                className={styles.submenuName}
                onClick={() => toPage(submenu.path)}
              >
                {searchConfigNameOrPath(submenu?.id, navList, "name", menu.name)}
              </div>
            ))}
        </div>
      </div>
    })
  }

  return (
    <div className={styles.footer}>
      <div className={styles.content}>
        <div className={styles.left}>
          <div className={styles.logo}>
            <img
              src={globalSetting?.platformLogo ? `${MINIIO_PREVIEW_PATH}?filePath=${globalSetting?.platformLogo}` : logo}
              alt=""/>
            <span
              className={styles.title}
              onClick={() => {
                navigate("/home");
              }}
            >
              {globalSetting?.platformName}
            </span>
          </div>
          <div className={styles.menuWrapper}>
            {sortNestedArrayByWeight(setWeightField(getItems(menuList || []), navList))}
          </div>
          {/* 友情链接 移到此处 */}
          <div className={styles.menuWrapper}>
            {globalSetting?.portalFriendshipLinkList
              ?.slice(0, 4)
              ?.map((item, index) => {
                return (
                  <div className={styles.menu}>
                    <div className={styles.menuName}>
                      {index === 0 ? "友情链接" : <>&nbsp;</>}
                    </div>
                    <div className={styles.submenuWrap}>
                      <div className={styles.submenuName}>
                        <a
                          href={item.link}
                          rel="noreferrer"
                          target="_blank"
                          style={{color: "#999"}}
                        >
                          {item?.name}
                        </a>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
        <div className={styles.right}>
          <div className={styles.blockTitle}>关注我们</div>
          <div className={styles.followUs}>
            {globalSetting?.attachmentList?.map((item) => (
              <div className={styles.followUs_item}>
                <img
                  src={`${MINIIO_PREVIEW_PATH}?filePath=${item?.link}`}
                  alt=""
                />
                <div className={styles.followUs_name}>{removeExtension(item.name || "")}</div>
              </div>
            ))}
          </div>

          {/*<div className={styles.linkP}>

          </div>*/}
          {/*<div className={styles.blockTitle}>联系我们</div>*/}
          <Descriptions column={1}>
            <Descriptions.Item label="建设运营">
              <span className={styles.subjectName}>
                {globalSetting?.subjectName}
              </span>
            </Descriptions.Item>
            <Descriptions.Item label="联系电话">
              {globalSetting?.subjectPhone}
            </Descriptions.Item>
            {globalSetting?.subjectEmail && (
              <Descriptions.Item label="电子邮箱">
                {globalSetting?.subjectEmail}
              </Descriptions.Item>
            )}
            <Descriptions.Item label="地址">
              <span onClick={() => setOpen(true)} className={styles.address}>
                {globalSetting?.subjectAddress}
              </span>
            </Descriptions.Item>
            <Descriptions.Item>
              <span className={styles.link} onClick={openModal}>
                网站声明
              </span>
            </Descriptions.Item>
          </Descriptions>
        </div>
      </div>
      <div className={styles.bottom}>
        {globalSetting?.websiteRight}
        <a
          href="https://beian.miit.gov.cn/"
          rel="noreferrer"
          target="_blank"
          style={{padding: "0 1.25rem", color: "#999"}}
        >
          {globalSetting?.icp}
        </a>
        <a
          href="https://beian.mps.gov.cn/#/query/webSearch?code=51019002006186"
          rel="noreferrer"
          target="_blank"
        >
          <img src={beianImg} alt=""/>
          {globalSetting?.networkSecurity}
        </a>
      </div>
      {
        viewMap
      }
    </div>
  )
    ;
}
