import React, {ReactNode} from "react";
import styles from "./index.module.less";

export default function DcPage(props: { title: any; children: ReactNode }) {
  const {title, children} = props;

  return (
    <div className={styles.pageRoot} id="PageRoot">
      <div className={styles.pageHeader}>{title}</div>
      <div className={styles.pageMain}>{children}</div>
    </div>
  );
}
