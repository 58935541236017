import React, {useEffect, useState} from "react";
import DcPage from "@/components/DcPage";
import userinfoStyles from "@/views/backend/userInfo/index.module.less";
import {
  Breadcrumb,
  Button,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  message,
  Radio,
  Row,
  Select,
  Space,
  Spin
} from "antd";
import styles from "./index.module.less"
import {useLocation, useNavigate} from "react-router-dom";
import PrimaryTitle from "@/components/PrimaryTitle";
import DcValidator from "@/validator";
import {useRequest} from "ahooks";
import {getDictListByName} from "@/service/dict";
import {useUploadWithImg} from "@/components/UploadWithImg";
import {useUploadAuthProtocol} from "@/views/backend/admissionCert/AddCert/uploadAuthProtocol/uploadAuthProtocol";
import {getAllAgreement, getCustomerAuthList, getReAuthDetail, saveCertification} from "@/service/backendAuthCert";
import {getQueryString} from "@/utils";
import {useFormItemModalMap} from "@/components/FormItemModalMap";
import moment from "moment";
import {CheckCircleTwoTone} from "@ant-design/icons";
import {usePartnerTypeAgreement} from "@/views/backend/admissionCert/AddCert/partnerTypeAgreement/partnerTypeAgreement";
import {useNoticeCerting} from "@/views/backend/admissionCert/AddCert/noticeCerting";
import {
  useUploadEvaluateMaterials
} from "@/views/backend/admissionCert/AddCert/uploadEvaluateMaterials/uploadEvaluateMaterialsl";
import {useEvaluateMaterialsPDF} from "@/views/backend/admissionCert/AddCert/evaluateMaterialsPDF/evaluateMaterialsPDF";

const {Item} = Form
const {TextArea} = Input

export const PartnerTypeOptions = [
  {
    label: "数据使用方",
    value: 0,
    desc: "对平台现有公共或社会侧数据有需求的机构",
    dictName: "eco_partner_data_syf_file_type"
  },
  /*{
    label: "数据渠道方",
    value: 1
  }, */
  {
    label: "数据提供方",
    value: 2,
    desc: "提供公共数据资源的公共机构，提供社会资源的社会机构",
    dictName: "eco_partner_data_tgf_file_type",
  },
  {
    label: "数据参与方",
    value: 3,
    desc: "参与数据资源与申请、需求发布与认领等业务的机构",
    dictName: "eco_partner_data_cyf_file_type",
  },
  {
    label: "产品共创方",
    value: 4,
    desc: "利用数据要素创新工场参与数据产品开发的机构",
    dictName: "eco_partner_product_gcf_file_type",
  },
  {
    label: "产品展销方",
    value: 5,
    desc: "上架产品至平台，通过平台展示与推广产品的机构",
    dictName: "eco_partner_product_zxf_file_type",
  }
]

const DATE_FORMAT = "YYYY.MM.DD HH:mm:ss"

/**
 * @Author wangyubo
 * @Date 2024/3/14
 * @Path src/views/backend/admissionCert/AddCert
 * @IDE WebStorm
 * @Desc 准入认证申请/
 * */
export default function Page() {
  const location = useLocation();
  const id = getQueryString(location.search, 'id');
  /** 列表带过来的生态伙伴类型  */
  const pType = getQueryString(location.search, 'pType');
  /** type == second 重新认证  */
  const type = getQueryString(location.search, 'type');
  const [form] = Form.useForm()
  const navigate = useNavigate()

  /** 是否已阅读  */
  const [isRead, setIsRead] = useState(false);
  /** 提交成功后返回的参数 */
  const [resData, setResData] = useState<Record<any, any>>();
  /** 是否显示 我已阅读  组件 （如果对应partnerType协议有数据 则展示） */
  const [isShowReadAgreement, setIsShowReadAgreement] = useState(false);

  /** 获取经营状态列表  */
  const {data: businessStatusList} = useRequest(() => getDictListByName("business_status"))
  /** 企业性质列表  */
  const {data: enterpriseNatureList} = useRequest(() => getDictListByName("enterprise_nature"))
  /** 所属行业  */
  const {data: industryList} = useRequest(() => getDictListByName("industry"))

  /** 监听 某些表单字段  */
  const partnerTypeValue = Form.useWatch("partnerType", form)
  const enterpriseNatureValue = Form.useWatch("enterpriseNature", form)
  const enterpriseNameValue = Form.useWatch("enterpriseName", form)
  const enterpriseAbbrValue = Form.useWatch("enterpriseAbbr", form)


  /** 获取后台所有上传的协议  */
  const {data: agreementMap} = useRequest(getAllAgreement, {
    onSuccess: (res) => {
      const result = res[partnerTypeValue]
      if (result) {
        setIsShowReadAgreement(true)
      }
    }
  })

  /** 值变化时 重新匹配数据  */
  useEffect(() => {
    if (partnerTypeValue || agreementMap) {
      const result = agreementMap[partnerTypeValue]
      if (result) {
        setIsShowReadAgreement(true)
      } else {
        setIsShowReadAgreement(false)
      }
    }
  }, [partnerTypeValue, agreementMap]);

  /** 获取选择地址组件  */
  const {jsx: checkMap} = useFormItemModalMap({
    form,
    label: "注册地址",
    name: "registeredAddress",
    rules: [DcValidator.required(), DcValidator.noEmptyStart()],
  })

  /** 获取生态伙伴协议  */
  const {jsx: agreement, setOpen} = usePartnerTypeAgreement({
    partnerTypeValue: partnerTypeValue || 0,
    isRead,
    setIsRead,
  })

  /** 获取认证协议组件  */
  const {
    jsx: authProtocol,
    dataSource, typeList,
    setDataSource,
    handleSubTypeAttachmentMap,
    handleRestoreObjectToArray
  } = useUploadAuthProtocol({
    isDetail: false,
    second: type === "second",
    dictName: PartnerTypeOptions?.find(item => item.value === partnerTypeValue)?.dictName || "eco_partner_data_syf_file_type"
  })
  /** 获取 评估材料 组件  */
  const {
    jsx: evaluateMaterials,
    dataSource: dataSource2,
    typeList: typeList2,
    setDataSource: setDataSource2,
    handleSubTypeAttachmentMap: handleSubTypeAttachmentMap2,
    handleRestoreObjectToArray: handleRestoreObjectToArray2
  } = useUploadEvaluateMaterials({
    isDetail: false,
    second: type === "second",
    partnerType: partnerTypeValue
  })

  /** 资质评估材料弹窗  */
  const {jsx: evaluateMaterialsPDF, setOpen: openEvaluateMaterialsPDF} = useEvaluateMaterialsPDF()


  /** 获取上传 营业执照 img1  */
  const {
    jsx: uploadBusinessLicense,
    fileUrl: businessLicenseFileUrl,
    setFileList: setBusinessLicenseFileList
  } = useUploadWithImg({})

  /** 获取上传 法人身份证人像面  img2 */
  const {
    jsx: uploadLegalDelegateImage,
    fileUrl: legalDelegateImageFileUrl,
    setFileList: setLegalDelegateImageFileList
  } = useUploadWithImg({})

  /** 获取上传 法人身份证人像面  img3 */
  const {
    jsx: uploadLegalDelegateNational,
    fileUrl: legalDelegateNationalFileUrl,
    setFileList: setLegalDelegateNationalFileList
  } = useUploadWithImg({})

  /** 获取审核中提示弹窗  */
  const {jsx: noticeCerting, setOpen: setNoticeModalOpen} = useNoticeCerting({
    data: {
      partnerTypeText: PartnerTypeOptions?.find(item => item.value === partnerTypeValue)?.label,
      enterpriseNatureText: enterpriseNatureList?.find(item => item.value === enterpriseNatureValue)?.title,
      enterpriseName: enterpriseNameValue,
      enterpriseAbbr: enterpriseAbbrValue,
      id: resData?.id,
    }
  })


  /** 给每种认证协议数据增加type字段  */
  function handleAddType(arr: any[] = [], type: string) {
    return arr.map(item => ({
      ...item,
      type,
    }))
  }

  /** 获取客户认证信息  */
  const {run: runGetCustomerAuthList} = useRequest(getCustomerAuthList, {
    manual: true,
    onSuccess: (res) => {
      console.log("res:", res)
    }
  })

  /** 查重新认证详情  */
  const {loading: detailLoading, data: detail} = useRequest(getReAuthDetail, {
    ready: !!id,
    onSuccess: (res) => {
      console.log("res:", res)
      form.setFieldsValue({
        ...res,
        establishDate: moment(res.establishDate),
        businessTerm: moment(res.businessTerm),
        partnerType: Number(pType) || 0,
      })
      setBusinessLicenseFileList(res?.businessLicense);
      setLegalDelegateImageFileList(res?.legalDelegateImage);
      setLegalDelegateNationalFileList(res?.legalDelegateNational);
      if (!res?.subTypeAttachmentMap?.other) {
        res.subTypeAttachmentMap.other = [{
          id: "0",
          type: "Z",
          label: "其他",
          value: "other",
          subType: "other"
        }]
      }
      console.log("res.subTypeAttachmentMap:", res.subTypeAttachmentMap)
      setDataSource(handleRestoreObjectToArray(res?.subTypeAttachmentMap || {}))
      setDataSource2(handleRestoreObjectToArray2(res?.assessmentMaterialsMap || {}))
    }
  })


  /** 提交-初次认证（ps 现在无需区分初次认证的再次认证接口，）  */
  const {run: runSubmit, loading} = useRequest(saveCertification, {
    manual: true,
    debounceWait: 2000,
    debounceLeading: true,
    debounceTrailing: false,
    onSuccess: (res) => {
      setResData(res);
      setNoticeModalOpen(true)
      /** 有id 是再次认证，认证后需要消费掉登陆给的认证数据  */
      if (id) {
        runGetCustomerAuthList()
      }
    }
  })

  /** 校验 dataSource的必填数据  */
  function validateDataSource() {
    let result = true;
    for (let i = 0; i < typeList.length; i++) {
      const item = typeList[i];
      const typeArr = dataSource.filter(fItem => fItem.value === item.value);
      if (item.value === "Z") break;
      if (typeArr?.length === 1 && !typeArr[0].link) {
        message.warning(`请上传${item.label}`)
        result = false
        return false;
      }
    }
    return result;
  }

  /** 校验 dataSource2 的必填数据  */
  function validateDataSource2() {
    let result = true;
    for (let i = 0; i < typeList2.length; i++) {
      const item = typeList2[i];
      const typeArr = dataSource2.filter(fItem => fItem.value === item.value);
      if (item.value === "Z") break;
      if (typeArr?.length === 1 && !typeArr[0].link) {
        message.warning(`请上传${item.label}`)
        result = false
        return false;
      }
    }
    return result;
  }

  /** 获取对应类型的协议数据 然后过滤掉ID字段  */
  function handleDataSourceItem(type: string) {
    const temp = dataSource.filter(item => item.type === type).map(item => ({
      ...item,
      id: undefined
    }))
    /** 判断是否有数据 为空  */
    if (temp.every(item => item?.link?.length > 0)) return temp;
    /** 有一条为空 证明是默认数据 可以直接不给后端  */
    return []
  }

  /** 监听表单值得变化  */
  function handleValuesChange(value) {
    /** 如果切换生态伙伴类型  */
    if (value.partnerType) {
      setIsRead(false)
    }
  }


  /** 提交  */
  function handleSubmit() {
    if (!validateDataSource()) return;
    if (!validateDataSource2()) return;
    form.validateFields().then(values => {
      if (isShowReadAgreement && !isRead) {
        message.warning("请先阅读协议并勾选我已阅读");
        return;
      }
      const params = {
        ...values,
        businessTerm: moment(values?.businessTerm).format(DATE_FORMAT),
        establishDate: moment(values?.establishDate).format(DATE_FORMAT),
        businessLicense: businessLicenseFileUrl,
        legalDelegateImage: legalDelegateImageFileUrl,
        legalDelegateNational: legalDelegateNationalFileUrl,
        subTypeAttachmentMap: handleSubTypeAttachmentMap(dataSource),
        assessmentMaterialsMap: handleSubTypeAttachmentMap2(dataSource2)
      }
      console.log("params:", params)
      runSubmit(params);
    }).catch(err => {
      message.error("请输入完整信息")
    })
  }

  return <DcPage title={<div className={userinfoStyles.Breadcrumb}>
    <Breadcrumb>
      <Breadcrumb.Item>
        <span className={userinfoStyles.BreadcrumbParent}>账号管理</span>
      </Breadcrumb.Item>
      <Breadcrumb.Item>
        <span className={userinfoStyles.BreadcrumbParent}>准入认证</span>
      </Breadcrumb.Item>
      <Breadcrumb.Item>
        <span className={userinfoStyles.BreadcrumbParent}>准入认证申请</span>
      </Breadcrumb.Item>
    </Breadcrumb>
  </div>}>
    <Spin spinning={loading || detailLoading}>
      {/* 标题行 */}
      <div className={styles.header_line}>
      <span className={styles.header_back} onClick={() => {
        navigate("/backend/admissionCert")
      }}>{"< 返回准入认证"}</span>
        <span className={styles.header_name}>准入认证申请</span>
        <span></span>
      </div>
      {/* 步骤行 */}
      <div className={styles.step_warp}>
        <div className={styles.item}>
          <div className={`${styles.step_num} ${styles.current_item}`}>1</div>
          <div className={styles.step_name}>填写企业认证资料</div>
          <div className={styles.step_split_line}></div>
        </div>
        <div className={styles.item}>
          <div className={`${styles.step_num} ${styles.last_item}`}>2</div>
          <div className={styles.step_name}>选择企业实名认证</div>
          <div className={styles.step_split_line}></div>
        </div>
        <div className={styles.item}>
          <div className={`${styles.step_num} ${styles.completed_item}`}>3</div>
          <div className={styles.step_name}>完成</div>
        </div>
      </div>
      <div className={styles.scroll_main}>
        {/* 表单 */}
        <div className={styles.form_warp}>
          <Form labelCol={{span: 6}} wrapperCol={{span: 18}} form={form}
                onValuesChange={handleValuesChange}
                initialValues={{
                  partnerType: 0,
                }}>
            <Row>
              <Col span={24}>
                <Item label={"申请成为"} name="partnerType" required={true} labelCol={{flex: "7.5rem"}}>
                  {/*<Radio.Group options={PartnerTypeOptions}></Radio.Group>*/}
                  <Radio.Group>
                    {
                      PartnerTypeOptions.filter(i => typeof i.value === 'number').map(item => {
                        return <div style={{display: "inline-block"}}>
                          <Radio value={item.value}>{item.label}</Radio>
                          {
                            detail?.customerAuthList?.findIndex((authItem: any) => authItem.partnerType === item.value) > -1 &&
                            <div
                              title={"已认证"}
                              style={{
                                display: "inline-block",
                                fontSize: "0.875rem",
                                marginRight: "2rem",
                                transform: "translateX(-0.75rem)"
                              }}>
                              <CheckCircleTwoTone twoToneColor={"#26d831"}></CheckCircleTwoTone>
                            </div>
                          }
                        </div>
                      })
                    }
                  </Radio.Group>
                </Item>
              </Col>
            </Row>
            <div className={styles.desc_warp}>
              <div>角色介绍：<span>{PartnerTypeOptions?.find(item => item.value === partnerTypeValue)?.desc || ""}</span>
              </div>
              {/*<div>开通功能：<span>数据大厅-数据需求、数据大厅-数据资源</span></div>*/}
              <div>如有任何疑问或需要帮助，请随时与我们联系 028-61556586</div>
            </div>
            <PrimaryTitle title={"主体信息"}></PrimaryTitle>
            <div className={styles.form_content_warp}>
              <Row>
                <Col span={24}>
                  <Item label="企业性质" name={"enterpriseNature"} rules={[DcValidator.required()]}
                        labelCol={{span: 3}}>
                    <Radio.Group
                      options={enterpriseNatureList?.map((item: any) => ({label: item?.title, value: item?.value}))}/>
                  </Item>
                </Col>
                <Col span={12}>
                  <Item label="企业名称" rules={[DcValidator.required(), DcValidator.primaryText()]}
                        name={"enterpriseName"}>
                    <Input placeholder='请输入' maxLength={50}/>
                  </Item>
                </Col>
                <Col span={12}>
                  <Item label="企业简称" rules={[DcValidator.required(), DcValidator.primaryText()]}
                        name={"enterpriseAbbr"}>
                    <Input placeholder='请输入' maxLength={50}/>
                  </Item>
                </Col>
                <Col span={12}>
                  <Item label="统一社会信用代码" rules={[DcValidator.required(), DcValidator.primaryText()]}
                        name={"creditCode"}>
                    <Input
                      placeholder='请输入'
                      maxLength={30}
                      disabled={!!detail}
                    />
                  </Item>
                </Col>
                <Col span={12}>
                  <Item label="经营状态" rules={[DcValidator.required()]} name={"businessStatus"}>
                    <Select placeholder='请选择' options={businessStatusList} fieldNames={{label: "title"}}/>
                  </Item>
                </Col>
                <Col span={12}>
                  <Item label="成立日期" rules={[DcValidator.required()]} name={"establishDate"}>
                    <DatePicker placeholder='请选择' style={{width: "100%"}}/>
                  </Item>
                </Col>
                <Col span={12}>
                  <Item label="营业期限" rules={[DcValidator.required()]} name={"businessTerm"}>
                    <DatePicker placeholder='请选择' style={{width: "100%"}}/>
                  </Item>
                </Col>
                <Col span={12}>
                  <Item label="注册资本" rules={[DcValidator.required()]} name={"registeredCapital"}>
                    <InputNumber placeholder='请输入' max={999999999999} addonAfter={<span>元</span>}
                                 style={{width: "100%"}}/>
                  </Item>
                </Col>
                {/* 注册地址 */}
                <Col span={12}>
                  <Item label={"注册地址"} name={"registeredAddress"} rules={[DcValidator.required()]}>
                    <Input></Input>
                  </Item>
                  {/*{checkMap}*/}
                </Col>
                <Col span={12}>
                  <Item label="法定代表人" rules={[DcValidator.required()]} name={"legalDelegate"}>
                    <Input placeholder='请选择' maxLength={10}/>
                  </Item>
                </Col>
                <Col span={12}>
                  <Item label="法定代表人联系方式" rules={[DcValidator.required(), DcValidator.phone()]}
                        name={"legalDelegatePhone"}>
                    <Input placeholder='请选择' maxLength={11}/>
                  </Item>
                </Col>
                <Col span={12}>
                  <Item label="所属行业" rules={[DcValidator.required()]} name={"industry"}>
                    <Select placeholder='请选择' options={industryList} fieldNames={{label: "title"}}/>
                  </Item>
                </Col>
                <Col span={12}>
                  <Item label="营业执照" rules={[DcValidator.required()]} name={"businessLicense"}>
                    {uploadBusinessLicense}
                  </Item>
                </Col>
                <Col span={12}>
                  <Item label="法人身份证人像面" rules={[DcValidator.required()]} name={"legalDelegateImage"}>
                    {uploadLegalDelegateImage}
                  </Item>
                </Col>
                <Col span={12}>
                  <Item label="法人身份证国徽面" rules={[DcValidator.required()]} name={"legalDelegateNational"}>
                    {uploadLegalDelegateNational}
                  </Item>
                </Col>
                <Col span={24}>
                  <Item label="企业简介" labelCol={{span: 3}} wrapperCol={{span: 21}} name={"introduction"}>
                    <TextArea placeholder='请输入' maxLength={500} showCount/>
                  </Item>
                </Col>
              </Row>
            </div>
          </Form>
        </div>
        <PrimaryTitle title={"相关附件"}></PrimaryTitle>
        {authProtocol}
        <PrimaryTitle title={"评估材料"} rightBtn={<Button onClick={() => openEvaluateMaterialsPDF(true)}
                                                           type={"link"}>查看评估材料清单</Button>}></PrimaryTitle>
        {evaluateMaterials}
      </div>
      <div className={styles.footer}>
        <div className={styles.checked_line} r-if={isShowReadAgreement}>
          <Checkbox checked={isRead} onClick={() => {
            if (!isRead) {
              setOpen(true)
            }
          }}></Checkbox>
          <span className={styles.checked_line_read}>我已阅读并接受</span>
          <span className={styles.checked_line_name}
                onClick={() => setOpen(true)}>
            {`《${PartnerTypeOptions?.find(item => item.value === partnerTypeValue)?.label}服务协议》`}
          </span>
        </div>
        <Space>
          <Button type={"primary"} onClick={handleSubmit}>提交认证</Button>
          <Button disabled>下一步</Button>
          <Button onClick={() => {
            navigate("/backend/admissionCert")
          }}>取消</Button>
        </Space>
      </div>
    </Spin>
    {/* 生态伙伴协议 */}
    {agreement}
    {/* 审核中提示弹窗 */}
    {noticeCerting}
    {/* 资质评估材料弹窗 */}
    {evaluateMaterialsPDF}
  </DcPage>;
}