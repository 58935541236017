import React, {useEffect, useRef, useState} from "react";
import {Button, message, Progress, Space, Table, Upload} from "antd";
import {MINIIO_UPLOAD_PATH} from "@/service/common";
import {useUploadWithTable} from "@/components/UploadWithTable";
import styles from "./index.module.less"
import {RcFile} from "antd/lib/upload/interface";
import {uniqueId} from "lodash";
import {useUpdateEffect} from "ahooks";
import {useGetDict} from "@/hooks/useOptions";

const UPLOAD_TYPE_LIST = ["doc", "docx", "pdf", "png", "jpeg", "jpg"]

/**
 * @Author wangyubo
 * @Date 2024/7/23
 * @Path src\views\backend\admissionCert\AddCert\uploadEvaluateMaterials\uploadEvaluateMaterialsl.tsx
 * @IDE uploadEvaluateMaterialsl.tsx
 * @Desc 上传评估材料
 **/

/** 创建 英文字母 和 数字的映射 数字当id，字母当类型  */
const letterToNumberMap = new Map();
const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXY'

for (let i = 0; i < alphabet.length; i++) {
  const letter = alphabet[i];
  const number = i + 1;
  letterToNumberMap.set(number, letter)
}

/*const ecoPartnerAttachmentTypeList: any[] = [
  {
    label: "资质评估",
    value: "A",
  }, {
    label: "合规评估",
    value: "B",
  }, {
    label: "安全能力评估",
    value: "C",
  }
]*/

const AcceptTypes = [".doc", ".docx", ".pdf", ".jpg", ".png", ".jpeg", ".zip", ".rar"]

interface UploadAuthProtocolProps {
  /** 是否使用详情模式  */
  isDetail?: boolean;
  /** 是否 再次编辑  */
  second?: boolean
  partnerType?: number
}

/**
 * @Author wangyubo
 * @Date 2024/3/15
 * @Path src/views/backend/admissionCert/AddCert/uploadAuthProtocol
 * @IDE WebStorm
 * @Desc 上传认证协议 组件
 * */
export function useUploadEvaluateMaterials(props: UploadAuthProtocolProps) {
  const {isDetail, second, partnerType} = props

  /** 表格数据  */
  const [dataSource, setDataSource] = useState<any[]>([]);
  /**  */
  const [fileList, setFileList] = useState();

  const addRef = useRef<HTMLButtonElement>(null)
  /** 当前点击的行 */
  const [currentRecord, setCurrentRecord] = useState<Record<any, any>>();

  /** 获取 上传协议的类型  */
  const {formatList: ecoPartnerAttachmentTypeList} = useGetDict("assessment_materials")
  /*  const {formatList: ecoPartnerAttachmentTypeList} = useGetDict(dictName, {
        ready: !!dictName,
        refreshDeps: [dictName],
      })*/

  /** 获取上传相关的三个方法  */
  const {previewFile, handleProgressStatus, handleGetFileType, checkFileType} = useUploadWithTable({
    accept: AcceptTypes,
  })

  dataSource.sort((a, b) => (a.type > b.type ? 1 : b.type > a.type ? -1 : 0));


  function initTableData() {
    const initData = ecoPartnerAttachmentTypeList.map((item, index) => ({
      id: String(index + 1),
      type: letterToNumberMap.get(index + 1),
      label: item.label,
      value: item.value,
    }))
    setDataSource(initData)
  }

  /** 新增时  处理表格的初始值  */
  useEffect(() => {
    if (ecoPartnerAttachmentTypeList && !isDetail) {
      initTableData()
    }
  }, [ecoPartnerAttachmentTypeList, isDetail, second]);

  /** 变更 认证类型时 重新处理数据  */
  useUpdateEffect(() => {
    if (partnerType) {
      initTableData()
    }
  }, [partnerType])


  function UploadTableCol(props: any) {
    const {record} = props
    return <div>
      <span style={{color: "red", display: record.type !== "Z" ? "inline" : "none"}}>{"*"}</span>
      {props.children}
      <Button type={"primary"} style={{margin: "0 0.75rem"}} r-if={!isDetail} onClick={() => {
        if (isDetail) return;
        /** 限制类型的数量上限 20  */
        setCurrentRecord(record)
        addRef?.current?.click()
      }}>上传</Button>
    </div>
  }


  /** 删除  */
  function deleteFile(record) {
    /** 校验当前项是否是 这一类的最后一项，最后一项删除需要替换成一条空的默认数据  */
    const typeArr = dataSource.filter(item => item.type === record.type);
    const ind = dataSource.findIndex(item => item.id === record.id)
    const temp = [...dataSource];
    console.log("typeArr:", typeArr)
    if (typeArr.length === 1) {
      /** 造不同的数据  */
      const oldItem = typeArr[0]
      if (oldItem?.value === "other") {
        temp.splice(ind, 1, {
          id: "0",
          type: "Z",
          label: "其他文件",
          value: "other",
        });
      } else {
        temp.splice(ind, 1, {
          id: uniqueId("init"),
          type: oldItem.type,
          label: oldItem.label,
          value: oldItem.value,
        });
      }
      setDataSource(temp)
      return;
    }
    temp.splice(ind, 1);
    setDataSource(temp)
  }

  const columns = [
    {
      title: "评估类型",
      dataIndex: "type",
      width: "15%",
      render: (value, record, index) => {
        const rowSpan = dataSource.filter((item) => item.type === value).length;
        if (
          index === 0 ||
          (index > 0 && dataSource[index - 1].type !== record?.type)
        ) {
          return {
            children: <UploadTableCol
              record={record}>{record?.label}</UploadTableCol>,
            props: {
              rowSpan: rowSpan,
            },
          };
        } else {
          return {
            children: <UploadTableCol
              record={record}>{record?.label}</UploadTableCol>,
            props: {
              rowSpan: 0,
            },
          };
        }
      },
    },
    {
      title: '文件名称',
      dataIndex: 'name',
      width: "35%",
      render: (text: any, record: any,) => {
        if (record?.percent && record?.response?.code !== "200") {
          return <Progress key={record.id} percent={Math.round(record?.percent || 0)} style={{width: "85%"}}
            // @ts-ignore
                           title={handleProgressStatus(record) === "exception" ? `${record?.response?.msg}` : null}
                           status={handleProgressStatus(record)}></Progress>
        }
        return <span>{text}</span>
        /* if (record?.response?.code === "200" || record?.link || !record?.percent) {
           return <span>{text}</span>
         }
         return <Progress percent={Math.round(record?.percent || 0)} style={{width: "85%"}}
           // @ts-ignore
                          title={handleProgressStatus(record) === "exception" ? `${record?.response?.msg}` : null}
                          status={handleProgressStatus(record)}></Progress>*/
      }
    },
    {
      title: '上传时间',
      dataIndex: 'createTime',
      width: "35%",
    },
    {
      title: '操作',
      width: 200,
      dataIndex: 'messageNumber',
      render: (val: string, record: any) => {
        return (
          <Space r-if={record.link}>
            <Button type="text"
                    style={{color: '#7f8dff'}}
                    r-if={![".zip", ".rar"].includes(handleGetFileType(record?.name || ""))}
                    onClick={() => previewFile(record)}
            >预览</Button>
            {/* <Button type="text"
                    style={{color: '#7f8dff'}}
                    onClick={() => downloadFile(record)}
            >下载</Button>*/}
            <Button type="text" r-if={!isDetail} style={{color: '#7f8dff'}}
                    onClick={() => deleteFile(record)}
            >删除</Button>
          </Space>
        )
      },
    },
  ];


  /** 合并数据  */
  function mergeArrayByUniqueId(array1: any[], array2: any[]) {
    const idMap = {};
    /** 将数组1中的数据存储到 idMap中，以id作为键  */
    array1.forEach(item => {
      idMap[item.id] = item
    })

    /** 遍历数组2中的数据，根据id进行合并操作  */
    array2.forEach(newItem => {
      const {id} = newItem
      if (id in idMap) {
        idMap[id] = newItem
      } else {
        idMap[id] = newItem
      }
    })
    const mergeArray = Object.values(idMap)
    return mergeArray
  }

  /** 处理上传协议的数据 数组转对象  */
  function handleSubTypeAttachmentMap(arr: any[]) {
    const subTypeAttachmentMap = {};
    arr.filter(item => item.link).forEach(item => {
      const {id, value, ...rest} = item
      if (!subTypeAttachmentMap[value]) {
        subTypeAttachmentMap[value] = []
      }
      subTypeAttachmentMap[value].push(rest)
    })
    return subTypeAttachmentMap
  }


  /** 根据 ecoPartnerAttachmentTypeList的 value 值 找到 title */
  function handleGetTitle(value: string) {
    return ecoPartnerAttachmentTypeList.find(item => item.value === value)
  }

  /** 根据 ecoPartnerAttachmentTypeList的 value 值 找到  index  */
  function handleGetIndex(value: string) {
    return ecoPartnerAttachmentTypeList.findIndex(item => item.value === value)
  }

  /** 对象再还原成数组  */
  function handleRestoreObjectToArray(subTypeAttachmentMap) {
    const restoreArray: any[] = []
    /** 遍历分类后的对象  */
    for (const value in subTypeAttachmentMap) {
      if (Object.prototype.hasOwnProperty.call(subTypeAttachmentMap, value)) {
        const items = subTypeAttachmentMap[value]

        /** 遍历分类后的数组  */
        items.forEach((item, index) => {
          if (item.subType === "other" || ecoPartnerAttachmentTypeList.findIndex(ecoItem => ecoItem.value === item?.subType) > -1) {
            const restoredItem = {
              ...item,
              value,
              label: item.subType === "other" ? "其他文件" : handleGetTitle(item.subType)?.label,
              type: item.subType === "other" ? "Z" : letterToNumberMap.get(handleGetIndex(item.subType) + 1)
            }
            restoreArray.push(restoredItem)
          }
        })
      }
    }
    console.log("restoreArray:", restoreArray)
    /** 过滤一下可能出现的空数据（其他文件）  */
    if (isDetail) {
      return restoreArray?.filter(item => item.link)
    }
    return restoreArray
  }

  /** 上传前校验  */
  function beforeUpload(file: RcFile) {
    /** 每种类型数量 限制  */
    const currentTypeList = dataSource.filter(item => item.type === currentRecord?.type) || []
    if (currentTypeList.length >= 20) {
      message.warning('每种类型最多上传20个文件')
      return Upload.LIST_IGNORE
    }
    /** 大小限制  */
    const maxSize = 1024 * 1024 * 10;
    if (file.size > maxSize) {
      message.warning("文件超出大小")
      return Upload.LIST_IGNORE;
    }
    /** 类型限制  */
    return checkFileType(AcceptTypes, handleGetFileType(file.name.toLowerCase()))
  }

  const jsx = <div className={styles.content}>
    <Table columns={columns} bordered={true} dataSource={dataSource} rowKey={"id"}
           pagination={false}></Table>
    <div style={{display: "none"}}>
      <Upload action={MINIIO_UPLOAD_PATH}
              multiple={true}
              name={"file"}
              accept={AcceptTypes.join(",")}
              showUploadList={false}
              data={{
                basePath: "ysmf"
              }}
              headers={{
                "x-token-id": sessionStorage.getItem("token") as string,
              }}
              fileList={fileList}
              beforeUpload={beforeUpload}
              onChange={(info) => {
                const currentTypeList = dataSource.filter(item => item.type === currentRecord?.type) || []
                const content = info?.file?.response?.content
                const newData = {
                  ...info.file,
                  type: currentRecord?.type,
                  value: currentRecord?.value,
                  label: currentRecord?.label,
                  id: info.file.uid,
                  name: content?.fileName,
                  link: content?.filePath,
                  createTime: content?.uploadTime,
                };
                /** 处理默认数据：判断当前类型的数量，如果长度不等于1，合并后插入数据，
                 * 如果只有一条，判断这一条是默认数据 还是 上传的数据。默认数据需要替换，不是的话也直接插入数据,  */
                if (currentTypeList.length !== 1) {
                  setDataSource(mergeArrayByUniqueId(dataSource, [newData]));
                } else {
                  const firstItem = currentTypeList[0];
                  if (firstItem.link) {
                    setDataSource([...dataSource, newData]);
                  } else {
                    /** 替换数据  */
                    const index = dataSource.findIndex(item => item.id === firstItem.id)
                    const temp = [...dataSource];
                    temp.splice(index, 1, newData)
                    setDataSource(temp)
                  }
                }
              }}
      >
        <Button ref={addRef}>123</Button>
      </Upload>
    </div>
  </div>;
  return {
    jsx,
    dataSource,
    setDataSource,
    typeList: ecoPartnerAttachmentTypeList,
    ecoPartnerAttachmentTypeList,
    handleSubTypeAttachmentMap: (arr: any[]) => handleSubTypeAttachmentMap(arr),
    handleRestoreObjectToArray: (obj: any) => handleRestoreObjectToArray(obj)
  };
}