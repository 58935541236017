import React, {useRef, useState} from "react";
import {Button, Modal, Skeleton} from "antd";

import {useRequest} from "ahooks";
import {getPartnerTypeAgreement} from "@/service/backendAuthCert";
import {miniIoDownload} from "@/service/common";
import {renderAsync} from "docx-preview";
import styles from "./index.module.less"
import useCountdown from "ahooks/es/useCountDown";
import {PartnerTypeOptions} from "@/views/backend/admissionCert/AddCert";

/** 预览文件的配置  */
const previewConfig = {
  className: "docx-preview-warp",
  inWrapper: false,
  ignoreWidth: true,
  ignoreHeight: true,
}

interface PartnerTypeAgreementProps {
  partnerTypeValue: number;
  isRead: boolean;
  setIsRead: any;
}

/**
 * @Author wangyubo
 * @Date 2024/3/26
 * @Path src/views/backend/admissionCert/AddCert/partnerTypeAgreement
 * @IDE WebStorm
 * @Desc 生态伙伴协议
 * */
export function usePartnerTypeAgreement(props: PartnerTypeAgreementProps) {
  const {partnerTypeValue, setIsRead, isRead} = props
  const ref = useRef<any>(null);
  /**  */
  const [open, setOpen] = useState(false);
  /**  */
  const [disabled, setDisabled] = useState(true);
  /**  */
  const [loading, setLoading] = useState(true);
  /** 倒计时  */
  const [countDown] = useCountdown({
    leftTime: open ? 10 * 1000 : 0, onEnd: () => {
      setDisabled(false)
    }
  })


  useRequest(getPartnerTypeAgreement, {
    ready: open,
    defaultParams: [partnerTypeValue],
    onSuccess: (values) => {
      miniIoDownload(values.agreementPath).then(res => {
        renderAsync(res.data, ref.current, ref.current, previewConfig).then(r => {
          setLoading(false)
          const doms = document.getElementsByClassName("docx-preview-warp")
          Array.from(doms).forEach(i => {
            i.removeAttribute("style")
          })
        })
      })
    }
  })


  const jsx = <Modal
    width={1200}
    open={open}
    closable={false}
    className={styles.content}
    destroyOnClose
    title={<div
      className={styles.title}>{`《${PartnerTypeOptions?.find(item => item.value === partnerTypeValue)?.label}服务协议》`}</div>}
    footer={<div className={styles.footer}>
      <Button
        type="primary"
        size={"large"}
        shape="round"
        disabled={disabled}
        onClick={() => {
          setIsRead(true);
          setOpen(false);
        }}
      >
       <span style={{
         padding: "0 0.5rem",
         display: countDown === 0 ? "none" : "inline"
       }}> ({`${Math.round(countDown / 1000)}`})</span>
        我已阅读并同意
      </Button>
    </div>}>
    {
      loading && <Skeleton active></Skeleton>
    }
    <div ref={ref} className={styles.docx_preview_content}></div>
  </Modal>;
  return {jsx, setOpen};
}