import React, {useEffect, useState} from "react";
import {Button, Modal, Space} from "antd";
import {ExclamationCircleTwoTone} from "@ant-design/icons";
import styles from "./index.module.less"
import {useNavigate} from "react-router-dom";

/**
 * @Author wangyubo
 * @Date 2024/3/19
 * @Path src/components/reCertNoticeModal
 * @IDE WebStorm
 * @Desc 重新认证 强弱 提醒弹窗
 * */
export function useReCertNoticeModal() {
  const navigate = useNavigate()
  /**  */
  const [open, setOpen] = useState(false);

  /** 获取登录给的重新认证信息  */
  const baseInfo = JSON.parse(sessionStorage.getItem("baseInfo") || '{}')
  const reCertList = baseInfo?.customerAuths || []

  /** 只弹一次  */
  useEffect(() => {
    if (reCertList?.length > 0 || reCertList.some(item => item.versionChange === true)) {
      setOpen(true)
    }
  }, []);

  // 这次发版 隐藏这个弹窗
  const jsx = <Modal open={false} title={null} footer={null} closable={false}>
    <div className={styles.content}>
      <ExclamationCircleTwoTone twoToneColor={"#FE7235"}></ExclamationCircleTwoTone>
      <div
      className={styles.notice_text}>为了确保您的业务顺利进行，平台认证流程/协议已进行调整，请您尽快完成重新认证，以便更好的开展业务
    </div>
      <Space>
        <Button onClick={() => setOpen(false)}>下次再说</Button>
        <Button type={"primary"} onClick={() => {
          navigate("/backend/admissionCert")
          setOpen(false)
        }}>去认证</Button>
      </Space>
    </div>
  </Modal>;
  return {jsx, setOpen};
}