export function download(res: any, name?: string) {
    let fileName = name;

    if (!fileName) {
        try {
            const fileNameFromResponse = res.headers["content-disposition"].split("=").pop();

            if (fileNameFromResponse.toLowerCase().startsWith("utf-8''"))
                fileName = decodeURIComponent(fileNameFromResponse.replace(/utf-8''/i, ""));
            else fileName = fileNameFromResponse.replace(/['"]/g, "");
        } catch (error) {
            fileName = "Untitle";
        }
    }

    const a = document.createElement("a");
    const blob = new Blob([res.data]);
    a.download = fileName!;
    a.style.display = "none";
    a.href = URL.createObjectURL(blob);
    document.body.appendChild(a);
    a.click();
    a.remove();
    URL.revokeObjectURL(a.href);
}

export const downloadFileStream = (
  fileName: string | number,
  type: string,
  res: any
) => {
    console.log("data:", res)
    let blob = new Blob([res], {type});
    let url = window.URL.createObjectURL(blob);
    let a = document.createElement("a");
    a.download = fileName.toString();
    a.href = url;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
};

export interface FileKey {
    symbol: string;
    linkFileName: string;
    linkFilePath: string;
}

export function flatMapFileData(obj: any = {}, keys: FileKey[]) {
    const values = JSON.parse(JSON.stringify(obj));

    Object.keys(values).forEach((key) => {
        if (key.endsWith("Param")) {
            values[key.replace(/(.*)Param$/, "$1VO")] = values[key];
            delete values[key];
        }
        if (key.endsWith("Params")) {
            values[key.replace(/(.*)Params$/, "$1VOS")] = values[key];
            delete values[key];
        }
    });
    keys.forEach((item) => {
        const uploadKey = item.linkFileName.split(".")[0];
        const fileName = item.linkFileName.split(".")[1];
        const filePath = item.linkFilePath.split(".")[1];
        const vo = item.symbol;

        if (vo) {
            const uploadData = values[vo][uploadKey];
            if (uploadData) {
                delete values[vo][uploadKey];
                values[vo][fileName] = uploadData.fileName;
                values[vo][filePath] = uploadData.filePath;
            }
        } else {
            const uploadData = values[uploadKey];
            if (uploadData) {
                delete values[uploadKey];
                values[fileName] = uploadData.fileName;
                values[filePath] = uploadData.filePath;
            }
        }
    });

    Object.keys(values).forEach((key) => {
        if (key.endsWith("VO")) {
            values[key.replace(/(.*)VO/, "$1Param")] = values[key];
            delete values[key];
        }
        if (key.endsWith("VOS")) {
            values[key.replace(/(.*)VOS/, "$1Params")] = values[key];
            delete values[key];
        }
    });

    return values;
}

export function transformFileData(obj: any = {}, keys: FileKey[]) {
    const values = JSON.parse(JSON.stringify(obj));
    keys.forEach((item) => {
        const uploadKey = item.linkFileName.split(".")[0];
        const fileName = item.linkFileName.split(".")[1];
        const filePath = item.linkFilePath.split(".")[1];
        const vo = item.symbol;
        const uploadData = vo ? values[vo] : values;
        if (uploadData) {
            uploadData[uploadKey] = {
                fileName: uploadData[fileName],
                filePath: uploadData[filePath],
            };
            delete uploadData[fileName];
            delete uploadData[filePath];
        }
    });
    Object.keys(values).forEach((key) => {
        if (key.endsWith("VO")) {
            values[key.replace(/(.*)VO/, "$1Param")] = values[key];
            delete values[key];
        }
        if (key.endsWith("VOS")) {
            values[key.replace(/(.*)VOS/, "$1Params")] = values[key];
            delete values[key];
        }
    });
    return values;
}

/**
 * @desc 树转 数组
 * @param tree
 */
export function tree2list(tree: any[]) {
    const list: any[] = [];
    const queue = [...tree];
    while (queue.length) {
        const node = queue.shift();
        const children = node.children;
        if (children) {
            queue.push(...children)
        }
        list.push(node)
    }
    return list;
}
