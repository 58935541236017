import React, {useEffect, useState} from "react";
import {message, Modal, Upload} from "antd";
import {DownloadOutlined, LoadingOutlined, PlusOutlined} from "@ant-design/icons";
import {MINIIO_UPLOAD_PATH, miniIoDownload, miniIoPreview} from "@/service/common";
import {RcFile} from "antd/lib/upload/interface";
import {downloadFileStream} from "@/utils/file";
import {uniqueId} from "lodash";
import styles from "./index.module.less";

// const AcceptTypes = ["image/png,image/jpeg,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/pdf"]
const AcceptTypes = ["image/png, image/jpeg"]
const UPLOAD_TYPE_LIST = ["jpg", "png", "jpeg"]


function getBase64(img: RcFile, callback: (url: string) => void) {
  const render = new FileReader();
  render.addEventListener("load", () => callback(render.result as string))
  render.readAsDataURL(img)
}

interface UploadWithImgProps {
  /** 是否展示提示信息  */
  showPlaceholder?: boolean;
  /** 提示信息  */
  placeholder?: string;
  /** 文件质量大小  */
  maxSize?: number;
  /** 上传类型  */
  uploadTypeList?: string[];
  /** 自定义打开文件的类型  */
  accept?: string[]
}

/**
 * @Author wangyubo
 * @Date 2024/1/24
 * @Path src/components/UploadWithImg
 * @IDE WebStorm
 * @Desc index
 * */
export function useUploadWithImg(props: UploadWithImgProps) {

  const {
    showPlaceholder,
    placeholder,
    maxSize = 2,
    uploadTypeList = UPLOAD_TYPE_LIST,
    accept = AcceptTypes
  } = props

  /** loading */
  const [loading, setLoading] = useState(false);
  /** 转成blob的 预览地址 */
  const [imageUrl, setImageUrl] = useState<string>();
  /** 文件列表 */
  const [fileList, setFileList] = useState<any[]>([]);
  /** 预览弹窗 */
  const [previewVisible, setPreviewVisible] = useState(false);
  /** 返给后端的地址 */
  const [fileUrl, setFileUrl] = useState<string>("");

  /** 上传前校验  */
  function beforeUpload(file: RcFile) {
    /** 大小限制  */
    const maxSizeToM = 1024 * 1024 * maxSize;
    if (file.size > maxSizeToM) {
      message.warning("文件超出大小")
      return Upload.LIST_IGNORE;
    }
    let result = false;
    /** 类型限制  */
    for (let i = 0; i < uploadTypeList.length; i++) {
      const typeInclues = file.name.toLowerCase().endsWith(uploadTypeList[i])
      if (typeInclues) {
        result = true;
        return;
      }
    }
    if (!result) {
      message.warning("文件类型不匹配")
    }
    return result || Upload.LIST_IGNORE;
  }

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined/> : <PlusOutlined/>}
      <div style={{marginTop: "0.5rem"}}>上传</div>
    </div>
  )

  /** 处理类型  */
  function handleType(name: string) {
    console.log("name:", name)
    if (name.endsWith(".jpg")) {
      return "image/jpeg"
    }
    if (name.endsWith(".jpeg")) {
      return "image/jpeg"
    }
    if (name.endsWith(".png")) {
      return "image/png"
    }
    if (name.endsWith(".svg")) {
      return "image/svg+xml"
    }
    if (name.endsWith(".gif")) {
      return "image/gif"
    }
    if (name.endsWith(".webp")) {
      return "image/webp"
    }
    if (name.endsWith(".bmp")) {
      return "image/bmp"
    }
    if (name.endsWith(".tiff")) {
      return "image/tiff"
    }
    if (name.endsWith(".tif")) {
      return "image/tiff"
    }
    if (name.endsWith(".ico")) {
      return "image/ico"
    }
    message.error("未知文件类型，预览失败")
    return "application/octet-stream"
  }

  function handlePreview() {
    setPreviewVisible(true)
  }

  function handleDownload() {
    const name = fileList[0]?.fileName || fileList[0]?.response?.content?.fileName
    const path = fileList[0]?.filePath || fileList[0]?.response?.content?.filePath
    miniIoDownload(path).then(res => {
      if (res) {
        message.success("开始下载，将很快完成")
        downloadFileStream(`${name}`, "application/octet-stream", res.data)
      }
    })
  }

  /** 回显图片 给外部使用  */
  function handleSetFileListByUrl(link: string) {
    if (!link || link.length === 0) return;
    const nameArr = link.split("_")
    console.log("nameArr：", nameArr)
    miniIoPreview(link).then(res => {
      const blob = new Blob([res.data], {type: handleType(link)})
      const url = window.URL.createObjectURL(blob)
      setFileList([{
        uid: uniqueId("file"),
        name: nameArr[nameArr.length - 1],
        fileName: nameArr[nameArr.length - 1],
        status: "done",
        filePath: link,
        url: url,
      }])
      setFileUrl(link);
      setImageUrl(url)
    })
  }


  useEffect(() => {
    console.log(fileList)
  }, [fileList]);

  const jsx = <div>
    <Upload
      action={MINIIO_UPLOAD_PATH}
      listType={"picture-card"}
      name={"file"}
      maxCount={1}
      accept={accept.join(",")}
      fileList={fileList}
      data={{
        basePath: "ysmf"
      }}
      headers={{
        "x-token-id": sessionStorage.getItem("token") as string,
      }}
      beforeUpload={beforeUpload}
      onRemove={() => {
        setImageUrl(undefined);
        setFileList([])
        setFileUrl("")
      }}
      onPreview={() => handlePreview()}
      onDownload={() => handleDownload()}
      onChange={({file, fileList}) => {
        setFileList(fileList)
        if (file.status === "uploading") {
          // setLoading(true);
        }
        if (file.status === "done") {
          setLoading(false);
          const path = file?.response?.content?.filePath
          if (!path) {
            message.error("上传文件失败")
            return;
          }
          setFileUrl(file?.response?.content?.filePath)
          miniIoPreview(path).then(res => {
            const blob = new Blob([res.data], {type: handleType(path)})
            const src = window.URL.createObjectURL(blob)
            setImageUrl(src)
            console.log("src:", src)

          })

        }
      }}
      showUploadList={{
        showDownloadIcon: true,
        downloadIcon: <DownloadOutlined/>,
        showPreviewIcon: true,
        showRemoveIcon: true,
      }}
    >
      {fileList.length === 1 ? null : uploadButton}
    </Upload>
    {
      showPlaceholder && <div
        className={styles.uploaddesc}>{placeholder || "建议尺寸6.25rem * 6.25rem，图片大小不超过2M，支持格式.jpg .png"}
      </div>
    }
    <Modal
      open={previewVisible}
      title={fileList[0]?.fileName || fileList[0]?.response?.content?.fileName}
      footer={null}
      onCancel={() => setPreviewVisible(false)}
    >
      <img
        alt=""
        style={{width: "100%"}}
        src={imageUrl}
      />
    </Modal>
  </div>;

  return {jsx, fileList, fileUrl, setFileList: (link: string) => handleSetFileListByUrl(link)};
}