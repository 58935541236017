import {Rule} from 'rc-field-form/lib/interface';
import {isNull} from 'lodash';

function required(message?: string): Rule {
  if (message) {
    return { required: true, message }
  }
  return { required: true }
}

function max(num: number, isEqual = true): Rule {
  return {
    validator: (rule, value, callback) => {
      if (isNull(value)) {
        callback();
      } else {
        if (isEqual && value > num) {
          callback(`最大值${num}`);
          return;
        }
        if (!isEqual && value >= num) {
          callback(`最大值小于${num}`);
          return;
        }
        callback();
      }
    }
  }
}

function min(num: number, isEqual = true): Rule {
  return {
    validator: (rule, value, callback) => {
      if (isNull(value)) {
        callback();
      } else {
        if (isEqual && value < num) {
          callback(`最小值${num}`);
          return;
        }
        if (!isEqual && value <= num) {
          callback(`最小值大于${num}`);
          return;
        }
        callback();
      }
    }
  }
}

function maxLength(num: number): Rule {
  return {
    validator: (rule, value, callback) => {
      if (value && value.length > num) {
        callback(`最大长度${num}`);
      } else {
        callback();
      }
    }
  }
}

function minLength(num: number): Rule {
  return {
    validator: (rule, value, callback) => {
      if (value && value.length < num) {
        callback(`最小长度${num}`);
      } else {
        callback();
      }
    }
  }
}

function phone(): Rule {
  const reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
  return regex(reg, '手机格式错误');
}

/** 校验普通字符的输入  */
function primaryText() {
  return {
    pattern: /^([\p{P}A-Za-z0-9\u4e00-\u9fa5])*$/u,
    message: "请不要输入空格等特殊字符",
  }
}

function email() {
  const reg = /^([a-zA-Z0-9]+[_|.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,3}$/;
  return regex(reg, '邮箱格式错误');
}

function idNo() {
  const reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
  return regex(reg, '身份证格式错误');
}

/** 不使用空格开头  */
function noEmptyStart() {
  return {
    pattern: /^\S/,
    message: "请不要使用空格开头",
  }
}

function regex(exp: string | RegExp, errorMsg: string = `格式错误`): Rule {
  return {
    validator: (rule, value, callback) => {
      const regExp = new RegExp(exp);
      if (!value) {
        callback();
      }
      if (!regExp.test(value)) {
        callback(errorMsg);
      } else {
        callback();
      }
    }
  }
}

const DcValidator = {
  required,
  min,
  max,
  maxLength,
  minLength,
  idNo,
  regex,
  phone,
  email,
  noEmptyStart,
  primaryText,
}

export default DcValidator;