import { useEffect, useState } from "react";
import { Button, Cascader, Form, Input, message, Modal, Space } from "antd";
import {
  creatConsultationMeaasge,
  getconsultationType,
} from "@/service/common";
import { InMessageType, MeaasgeParam } from "@/service/interface";
import storeService from "@/service/store.service";
import { tree2list } from "@/utils/file";

// 留言咨询
export const useMsgModal = (formValues?: MeaasgeParam & { title?: string }) => {
  const [form] = Form.useForm();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [treeData, setTreeData] = useState<InMessageType[]>([]);
  const userInfo = storeService.getBaseInfo();

  useEffect(() => {
    console.log("userInfo:", userInfo);
    userInfo &&
      form.setFieldsValue({
        ...userInfo,
        contactPhone: userInfo.phone,
      });
  }, [userInfo]);

  const getTreeData = async () => {
    const result = await getconsultationType();
    setTreeData(result);
  };

  useEffect(() => {
    isModalOpen && getTreeData();
  }, [isModalOpen]);

  // 打开弹框
  const openModal = () => {
    setIsModalOpen(true);
  };
  // 关闭弹框并清除表单数据
  const closeModal = () => {
    setIsModalOpen(false);
    form.resetFields();
  };

  useEffect(() => {
    formValues && form.setFieldsValue(formValues);
  }, [formValues]);

  // 提交表单
  const onFinish = async (values: MeaasgeParam) => {
    console.log("==提交", values);

    const id = values?.messageType?.[values?.messageType?.length - 1];
    const name = tree2list(treeData).find((item) => item?.id === id)?.name;
    try {
      await creatConsultationMeaasge({
        ...values,
        /** 后端需要 name  */
        messageType: name,
      });
      message.success("提交成功");
      closeModal();
    } catch (error) {
      console.log("error", error);
    }
  };
  // 有预填入内容时，内容禁止删除修改
  const onKeyDown = (e: any) => {
    // 如果包含不能删除的区域，禁止
    if (formValues?.content) {
      const start = e.target.selectionStart;
      const end = formValues.content.length;
      const isDelete = e.code === "Backspace" && !(start > end);
      const isInsert = start < end;
      if (isDelete || isInsert) {
        e.preventDefault();
      }
    }
  };
  let checkText = "";
  let startVal = null;

  // 输入法键盘字符下不可输入，记录输入的起始位置
  function onCompositionStart(e: any) {
    if (
      formValues?.content &&
      !(e.target.selectionStart >= formValues.content?.length)
    ) {
      checkText = e.data; //记录选中的文字
      startVal = e.target.selectionStart;
    }
  }

  // 当在不可编辑的范围内输入是，结合输入文字开始位置startVal和输入结束
  // e.target.selectionEnd位置，通过字符串截取并接还原原来的字符。
  function onCompositionEnd(e: any) {
    if (startVal !== null && formValues?.content) {
      if (startVal < formValues.content.length) {
        const targetVal = e.target.value;
        const startTarget = targetVal.substring(0, startVal);
        const endTarget = targetVal.substring(e.target.selectionEnd);
        form.setFieldValue("content", startTarget + checkText + endTarget);
      }
    }
    startVal = null;
    checkText = "";
  }

  // 空格校验
  const validateValue = async (_, value: string) => {
    if (value && !value.trim()) return Promise.reject("内容不能为空");
    return Promise.resolve();
  };

  const jsx = (
    <Modal
      title={formValues?.title || "留言咨询"}
      width={800}
      /* centered
 forceRender*/
      footer={null}
      open={isModalOpen}
      onCancel={closeModal}
    >
      <Form
        form={form}
        autoComplete='off'
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 18 }}
        onFinish={onFinish}
      >
        <Form.Item
          label='咨询类型'
          name='messageType'
          rules={[{ required: true, message: "请选择问题类型" }]}
        >
          <Cascader
            showSearch
            allowClear
            placeholder='请选择'
            fieldNames={{ label: "name", value: "id" }}
            options={treeData}
          />
        </Form.Item>

        <Form.Item
          label='咨询内容'
          name='content'
          rules={[
            { required: true, message: "请输入问题描述" },
            { validator: validateValue },
          ]}
        >
          <Input.TextArea
            rows={4}
            placeholder='请输入'
            onKeyDown={onKeyDown}
            onCompositionStart={onCompositionStart}
            onCompositionEnd={onCompositionEnd}
            maxLength={500}
          />
        </Form.Item>

        <Form.Item
          label='联系人'
          name='contactName'
          rules={[{ required: true, message: "请输入联系人" }]}
        >
          <Input placeholder='请输入' maxLength={10} />
        </Form.Item>

        <Form.Item
          label='联系电话'
          name='contactPhone'
          extra='留下您的联系方式，我们的专属顾问会尽快和您联系~'
          rules={[
            {
              validateTrigger: ["onBlur", "onChange"],
              validator: (_, value) => {
                console.log("value:", value);
                if (value) {
                  const maskedPhoneRegex = /\d{3}\*{4}\d{4}/;
                  const primaryPhoneRegex = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
                  if (value?.includes("*")) {
                    const result = maskedPhoneRegex.test(value);
                    if (result) {
                      return Promise.resolve();
                    } else {
                      return Promise.reject(new Error("手机号格式不正确"));
                    }
                  }
                  const normalResult = primaryPhoneRegex.test(value);
                  if (normalResult) {
                    return Promise.resolve();
                  } else {
                    return Promise.reject(new Error("手机号格式不正确"));
                  }
                } else {
                  return Promise.resolve();
                }
              },
            },
          ]}
        >
          <Input placeholder='请输入' maxLength={11} />
        </Form.Item>

        <Form.Item
          label='企业名称'
          name='enterpriseName'
          rules={[
            { required: true, message: "请输入企业名称" },
            { validator: validateValue },
          ]}
        >
          <Input placeholder='请输入' maxLength={50} />
        </Form.Item>

        <Space size={20} style={{ width: "100%", justifyContent: "center" }}>
          <Button type='primary' shape='round' htmlType='submit'>
            确定
          </Button>
          <Button shape='round' onClick={closeModal}>
            取消
          </Button>
        </Space>
      </Form>
    </Modal>
  );
  return {
    openModal,
    jsx,
  };
};
