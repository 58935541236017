import {Navigate} from "react-router-dom";
import React from "react";
import {ignoreRoute} from '@/utils';

export default function RouteWrapper(props: any) {
    const {children, wrappers, path} = props;
    if (wrappers?.includes('login')) {
        const isLogin = sessionStorage.getItem('token');
        if (isLogin) {
            return children;
        }
        return <Navigate to={'/login'}/>
    } else if (wrappers?.includes('auth')) {
        const ignore: boolean = ignoreRoute(path);
        if (ignore) {
            return <Navigate to={'/404'}/>
        }
        return children;
    }
    return <>{children}</>
}