import React, { useState } from "react";
import {
  Button,
  Form,
  message,
  Modal,
  Progress,
  Space,
  Table,
  Upload,
} from "antd";
import {
  MINIIO_PREVIEW_PATH,
  MINIIO_UPLOAD_PATH,
  miniIoDownload,
} from "@/service/common";
import { UploadOutlined } from "@ant-design/icons";
import { RcFile } from "antd/lib/upload/interface";
import { downloadFileStream } from "@/utils/file";
import styles from "./index.module.less";
import { UploadFile } from "antd/es/upload/interface";

const FormItem = Form.Item;

/** ps：新的需求要原则上 不限制上传类型  */
const AcceptTypes = [];
// const AcceptTypes = [".doc", ".docx", ".pdf", ".jpg", ".png", ".jpeg", ".zip", ".rar", ".ppt", ".pptx", ".xlsx", ".xls"]
const DEFAULT_PLACEHOLDER = "";

// const DEFAULT_PLACEHOLDER = "最多上传10个文件，单个文件大小不超过10M，支持格式：.doc .docx .pdf .jpg .png .zip .rar .ppt .xls"

interface UploadWithTableProps {
  /** 是否展示提示信息  */
  showPlaceholder?: boolean;
  /** 提示信息  */
  placeholder?: string;
  label?: string;
  readonly?: boolean;
  maxCount?: number;
  /** 自定义打开文件的类型  */
  accept?: string[];
  disabled?: boolean;
}

/**
 * @Author wangyubo
 * @Date 2024/1/24
 * @Path src/components/UploadWithTable
 * @IDE WebStorm
 * @Desc index
 * */
export function useUploadWithTable(props: UploadWithTableProps) {
  const {
    label = "附件",
    readonly = false,
    maxCount = 10,
    accept = AcceptTypes,
    placeholder = DEFAULT_PLACEHOLDER,
    showPlaceholder = true,
    disabled = false,
  } = props;

  /** 已上传的列表 */
  const [fileList, setFileList] = useState<any[]>([]);

  /** 处理上传中的文件的进度条的状态  */
  function handleProgressStatus(r: any) {
    if (r?.response?.code === "200") {
      return "success";
    }
    if (r?.response && r?.response?.code !== "200") {
      return "exception";
    }
    return "normal";
  }

  const columns = [
    {
      title: <span style={{ textWrap: "nowrap", width: 160 }}>{"序号"}</span>,
      width: 160,
      render: (text: any, record: any, index: number) => index + 1,
    },
    {
      title: "文件名称",
      dataIndex: "name",
      width: 200,
      ellipsis: true,
      render: (text: any, record: any) => {
        if (record?.response?.code === "200" || record?.link) {
          return <span>{text}</span>;
        }
        return (
          <Progress
            percent={record?.percent}
            style={{ width: "85%" }}
            // @ts-ignore
            title={
              handleProgressStatus(record) === "exception"
                ? `${record?.response?.msg}`
                : null
            }
            status={handleProgressStatus(record)}
          ></Progress>
        );
      },
    },
    {
      title: "上传时间",
      dataIndex: "createTime",
      width: 200,
      ellipsis: true,
      /*render: (text: moment.MomentInput) => {
        return <span>{moment(text).format("YYYY-MM-DD HH:mm:ss")}</span>
      }*/
    },
  ];

  /** 检查文件类型  */
  function checkFileType(fileTypeList: string[], type: string) {
    if (accept.length === 0) return true;
    if (fileTypeList.includes(type)) {
      return true;
    } else {
      message.warning("文件类型不匹配");
      return Upload.LIST_IGNORE;
    }
  }

  /** 获取文件类型 带.  */
  function handleGetFileType(filename: string) {
    const regex = /(?:\.([^.]+))?$/;
    const match = regex.exec(filename);
    return match ? `.${match[1]}` : "";
  }

  /** 上传前校验  */
  function beforeUpload(file: RcFile) {
    /** 大小限制  */
    const maxSize = 1024 * 1024 * 10;
    if (file.size > maxSize) {
      message.warning("文件超出大小");
      return Upload.LIST_IGNORE;
    }
    return checkFileType(accept, handleGetFileType(file.name.toLowerCase()));
  }

  /** 删除已上传  */
  function handleDeleteItem(record: any) {
    Modal.confirm({
      title: "删除提醒",
      content: `是否要取消上传${record.name}？`,
      onOk: () => {
        const temp = [...fileList];
        const result = temp.filter((item) => item.id !== record.id);
        setFileList(result);
      },
    });
  }

  /** 下载  */
  function down(record: any) {
    miniIoDownload(record?.link).then((res) => {
      if (res) {
        message.success("开始下载，将很快完成");
        downloadFileStream(
          `${record?.name}`,
          "application/octet-stream",
          res.data
        );
      }
    });
  }

  /** 处理类型  */
  function handleType(name: string) {
    if (name.endsWith(".jpg")) {
      return "image/jpg";
    }
    if (name.endsWith(".jpeg")) {
      return "image/jpeg";
    }
    if (name.endsWith(".png")) {
      return "image/png";
    }
    if (name.endsWith(".pdf")) {
      return "application/pdf";
    }
    if (name.endsWith(".doc")) {
      return "application/pdf";
    }
    if (name.endsWith(".docx")) {
      return "application/pdf";
    }
    message.error("未知文件类型，预览失败");
    return "application/octet-stream";
  }

  /** 预览  */
  function preview(record: any) {
    const url = encodeURI(`${MINIIO_PREVIEW_PATH}?filePath=${record.link}`);
    const newWindow = window.open(url, "_blank");
    const loop = setInterval(() => {
      if (newWindow!.closed) {
        clearInterval(loop);
      } else {
        newWindow!.document.title = record?.name || "预览";
      }
    }, 500);
  }

  /** 把 表格 提出来  */
  const tableJsx = (
    <div
      className={styles.tableWarp}
      style={{ transform: `translateX(${readonly ? "-7.5rem" : "0"})` }}
    >
      {fileList.length > 0 ? (
        <Table
          rowKey={"id"}
          columns={[
            ...columns,
            {
              title: "操作",
              width: "10%",
              fixed: "right",
              render: (_, record) => {
                console.log("record：", record);
                return (
                  <Space>
                    <Button
                      type={"link"}
                      r-if={
                        ![".zip", ".rar"].includes(
                          handleGetFileType(record?.name || "")
                        )
                      }
                      onClick={() => preview(record)}
                    >
                      预览
                    </Button>
                    {/*<Button type={"link"}
                      onClick={() => down(record)}>下载</Button>*/}
                    {!readonly && !disabled && (
                      <Button
                        type={"link"}
                        onClick={() => handleDeleteItem(record)}
                      >
                        删除
                      </Button>
                    )}
                  </Space>
                );
              },
            },
          ]}
          dataSource={fileList}
          pagination={false}
          scroll={{ x: "max-content" }}
        ></Table>
      ) : (
        <span style={{ display: readonly ? "block" : "none" }}>暂无附件</span>
      )}
    </div>
  );

  /** 获取数组的 最后 n 个元素  */
  function getLastNElement(arr: any[] | undefined, n: number) {
    if (!arr) return [];
    if (n >= Number(arr?.length)) {
      return arr;
    } else {
      message.warning(`最多上传${n}个文件`);
      // 需求变更 由取最新n条 到 取之前的 n 条
      // return arr.slice(arr.length - n)
      return arr.slice(0, n);
    }
  }

  /** 处理上传时的几个字段  */
  function handleInfoFileList(list: UploadFile[]) {
    return getLastNElement(list, maxCount)?.map((item: any) => {
      return {
        ...item,
        id: item?.uid,
        link: item?.response?.content?.filePath || item?.link,
        createTime: item?.response?.content?.uploadTime || item?.createTime,
      };
    });
  }

  const jsx = (
    <>
      {!readonly && (
        <FormItem label={label}>
          <div className={styles.uploadWarp}>
            <Upload
              disabled={disabled}
              action={MINIIO_UPLOAD_PATH}
              // maxCount={maxCount}
              multiple={true}
              name={"file"}
              accept={accept.join(",")}
              showUploadList={false}
              fileList={fileList}
              data={{
                basePath: "ysmf",
              }}
              headers={{
                "x-token-id": sessionStorage.getItem("token") as string,
              }}
              beforeUpload={beforeUpload}
              onChange={(info) => {
                setFileList(handleInfoFileList(info.fileList));
                if (info.file.status === "done") {
                  const path = info.file?.response?.content?.filePath;
                  if (!path) {
                    message.error(info.file?.response?.msg);
                    return;
                  }
                }
              }}
            >
              <Button
                icon={<UploadOutlined></UploadOutlined>}
                disabled={disabled}
                type="primary"
              >
                上传附件
              </Button>
            </Upload>
            <span className={styles.uploaddesc}>
              {showPlaceholder && placeholder}{" "}
            </span>
          </div>
        </FormItem>
      )}
      {readonly ? (
        <FormItem label={label}>{tableJsx}</FormItem>
      ) : (
        <div>{tableJsx}</div>
      )}
    </>
  );
  return {
    jsx,
    fileList: fileList.map((item) => ({ ...item, id: undefined })),
    beforeUpload: (file: RcFile) => beforeUpload(file),
    setFileList,
    downloadFile: (record: any) => down(record),
    previewFile: (record: any) => preview(record),
    handleGetFileType: (filename: string) => handleGetFileType(filename),
    handleProgressStatus: (record: any) => handleProgressStatus(record),
    getLastNElement: (arr: any[], n: number) => getLastNElement(arr, n),
    columns,
    checkFileType: (fileTypeList: string[], type: string) =>
      checkFileType(fileTypeList, type),
  };
}
