import React, {useState} from "react";
import {Modal} from "antd";
import styles from "./index.module.less"


/**
 * @Author wangyubo
 * @Date 2024/3/26
 * @Path src/views/backend/admissionCert/AddCert/evaluateMaterialsPDF
 * @IDE WebStorm
 * @Desc 评估资质弹窗
 * */
export function useEvaluateMaterialsPDF() {
  /**  */
  const [open, setOpen] = useState(false);

  const jsx = <Modal
    width={1200}
    open={open}
    closable={false}
    className={styles.content}
    destroyOnClose
    onOk={() => setOpen(false)}
    onCancel={() => setOpen(false)}
    title={<div
      className={styles.title}>数据业务合作机构评估材料清单</div>}
  >
    <iframe src="/1.pdf" width={"100%"} height={600} style={{border: "none"}}
            title={"数据业务合作机构评估材料清单"}></iframe>
  </Modal>;
  return {jsx, setOpen};
}