import React, {useState} from "react";
import {Modal} from "antd";
import styles from "./index.module.less";
import {ClockCircleTwoTone} from "@ant-design/icons";
import {useNavigate} from "react-router-dom";

/**
 * @Author wangyubo
 * @Date 2024/3/27
 * @Path src/views/backend/admissionCert/AddCert/noticeCerting
 * @IDE WebStorm
 * @Desc 提交成功 提示 审核中
 * */
export function useNoticeCerting({data}: any) {
  const navigate = useNavigate()
  /** open */
  const [open, setOpen] = useState(false);

  const jsx = <Modal title={"准入认证申请"} open={open} width={800} closable={false} maskClosable={false}
                     okText={"查看详情"}
                     cancelText={"返回"}
                     onOk={() => {
                       navigate(`/backend/admissionCert/detail?id=${data?.id}`, {
                         replace: true,
                       })
                       setOpen(false)
                     }}
                     onCancel={() => {
                       navigate("/backend/admissionCert", {
                         replace: true,
                       })
                       setOpen(false)
                     }}
  >
    <div className={styles.result_line}>
      <ClockCircleTwoTone twoToneColor={"#FE7235"}></ClockCircleTwoTone>
      <div className={styles.result_text}>平台审核中</div>
      <div>请耐心等待或联系我们 028-61556586
      </div>
    </div>
    <div className={styles.container}>
      <div className={styles.box}>
        <div className={styles.left_element}>申请成为 ：</div>
        <div className={styles.left_element}>企业/机构性质 ：</div>
        <div className={styles.left_element}>企业/机构名称 ：</div>
        <div className={styles.left_element}>企业/机构简称 ：</div>
      </div>
      <div className={styles.box}>
        <div className={styles.element}>{data?.partnerTypeText}</div>
        <div className={styles.element}>{data?.enterpriseNatureText}</div>
        <div className={styles.element} title={data?.enterpriseName}>{data?.enterpriseName}</div>
        <div className={styles.element} title={data?.enterpriseAbbr}>{data?.enterpriseAbbr}</div>
      </div>
    </div>


  </Modal>;
  return {jsx, setOpen};
}