import { DefaultOptionType } from "rc-select/lib/Select";
import { Filter, Order } from "../interface/page";
import _ from "lodash";

/* 引入jsencrypt实现数据RSA加密 */
import JSEncrypt from "jsencrypt"; // 处理长文本数据时报错 jsencrypt.js Message too long for RSA
import { message } from "antd";
import { MINIIO_PREVIEW_PATH } from "@/service/common";

const publicKey =
  "MFwwDQYJKoZIhvcNAQEBBQADSwAwSAJBAJWNtLWM2Ikx6XP/bA8hWvAKdWTNTdRoPMUi3SflZs2Aq9Jpxe8NQmV74+rImKVy6MgK/uwybQ164olwfXxy6j0CAwEAAQ==";

/* JSEncrypt加密 */
export function rsaPublicData(data: any) {
  try {
    if (typeof data === "object") {
      data = JSON.stringify(data);
    }
    const jsencrypt = new JSEncrypt();
    jsencrypt.setPublicKey(publicKey);
    return jsencrypt.encrypt(data);
  } catch {
    throw new Error("获取公钥接口有误");
  }
}

/**
 * 枚举转换成数组
 * @param obj
 */
export function enum2options(obj: object): DefaultOptionType[] {
  return Object.entries(obj).map(([label, value]: [string, string]) => {
    return {
      label,
      value,
    };
  });
}

export function formData2Filters(originObj: any = {}): Filter[] {
  const obj = _.cloneDeep(originObj);
  return Object.keys(obj).reduce((p: any[], n: string) => {
    if (obj[n]) {
      if (!obj[n].value) {
        return p;
      }
      if (obj[n].type === "DATE_RANGE" || obj[n].type === "DATE_CHOOSE") {
        obj[n].value = obj[n].value
          ? [
              obj[n].value[0].format("YYYY-MM-DD"),
              obj[n].value[1].format("YYYY-MM-DD"),
            ]
          : [];
      }
      return [...p, { field: n, value: obj[n].value, type: obj[n].type }];
    }
    return p;
  }, []);
}

export function getKeyFromEnum(obj: any = {}, value: any): string {
  const item = Object.entries(obj).find(([key, val]) => {
    return value === val;
  });
  return item ? item[0] : "";
}

function getAll(menus: any): any {
  return _.flatMap(menus, function (n) {
    if (n.children?.length > 0) {
      return getAll(n.children);
    }
    return [n];
  });
}

export function ignoreRoute(route: string) {
  const baseInfo = JSON.parse(sessionStorage.getItem("baseInfo")!);
  if (baseInfo) {
    const menus: any = baseInfo.menus;
    const allMenus: any[] = getAll(menus);
    return !allMenus.some((item) => item.url === route);
  }
  return true;
}

export function sorter2Orders(sorter: any = []): Order[] {
  const list = Array.isArray(sorter) ? sorter : [sorter];
  return list.reduce((p, n) => {
    if (n.order) {
      p.push({
        column: n.field,
        asc: n.order === "ascend",
      });
    }
    return p;
  }, []);
}

export function isEmptyObject(obj: object): boolean {
  return JSON.stringify(obj) === "{}";
}

export function filterEmptyProps(obj: object) {
  return Object.entries(obj).reduce((p: any, [key, value]) => {
    p[key] = isEmptyObject(value) ? null : value;
    return p;
  }, {});
}

/**
 * 遍历 tree
 * @param {object[]} tree
 * @param {function} cb - 回调函数
 * @param {string} children - 子节点 字段名
 * @param {string} mode - 遍历模式，DFS：深度优先遍历 BFS：广度优先遍历
 * @return {void} Do not return anything
 */
export function treeForEach(
  tree: any[],
  cb: (item: any) => unknown,
  children = "children",
  mode = "DFS"
): any[] {
  if (!Array.isArray(tree)) {
    throw new TypeError("tree is not an array");
  }
  if (typeof children !== "string") {
    throw new TypeError("children is not a string");
  }
  if (children === "") {
    throw new Error("children is not a valid string");
  }

  // 深度优先遍历 depth first search
  function DFS(treeData: any[]) {
    for (const item of treeData) {
      cb(item);

      if (Array.isArray(item[children])) {
        DFS(item[children]);
      }
    }
  }

  // 广度优先遍历 breadth first search
  function BFS(treeData: any[]) {
    const queen = treeData;

    while (queen.length > 0) {
      const item = queen.shift();

      cb(item);

      if (Array.isArray(item[children])) {
        queen.push(...item[children]);
      }
    }
  }

  if (mode === "BFS") {
    BFS(tree);
  } else {
    DFS(tree);
  }
  return tree;
}

export function getQueryString(search: string, keyName: string) {
  const reg = new RegExp("(^|&)" + keyName + "=([^&]*)(&|$)", "i");
  const r = search.substr(1).match(reg);
  if (r != null) {
    return unescape(r[2]);
  }
  return "";
}

/** 统一防抖配置  */
export const DEBOUNCE_CONFIG = {
  debounceWait: 2000,
  debounceLeading: true,
  debounceTrailing: false,
};

/** 替换 /n成真正的 换行符 样式中设置 white-space: pre; 可换行  */
export function replaceNewLine(str = "") {
  return str.replace(/\/n/g, "\n");
}

/** 替换 /n成 空格  */
export function replaceNewLineWithSpace(str = "") {
  return str.replace(/\/n/g, " ");
}

/** 非安全域名下使用复制到剪切板  */
function fallbackCopyTextToClipboard(text: string) {
  const textArea = document.createElement("textarea");
  textArea.value = text;
  textArea.style.position = "fixed";
  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    const successful = document.execCommand("copy");
    const msg = successful ? "successful" : "unsuccessful";
    console.log("fallback: copying text command was " + msg);
    message.success("已复制到剪切板");
  } catch (err) {
    console.error("fallback: Oops, unable to copy ", err);
  }
}

/** 复制到剪切板   */
export function copyTextToClipboard(text: string) {
  if (!navigator?.clipboard) {
    fallbackCopyTextToClipboard(text);
    return;
  }
  navigator.clipboard
    .writeText(text)
    .then(() => {
      message.success("已复制到剪切板");
    })
    .catch((err) => {
      console.error("Error copying text to clipboard", err);
      fallbackCopyTextToClipboard(text);
    });
}

// 判断是否为字符串
export function isString(something: any) {
  return Object.prototype.toString.apply(something) === "[object String]";
}

// 判断是否为js对象
export function isObject(something: any) {
  return Object.prototype.toString.apply(something) === "[object Object]";
}

// 判断是否为数组
export function isArray(something: any) {
  return Object.prototype.toString.apply(something) === "[object Array]";
}

// 判断是否为函数
export function isFunction(something: any) {
  return Object.prototype.toString.apply(something) === "[object Function]";
}

// 判断是否为null
export function isNull(something: any) {
  return something === null;
}

// 简单深拷贝
export function deepCopy(data: any) {
  if (!isObject(data) && !isArray(data)) {
    return data;
  }
  return JSON.parse(JSON.stringify(data));
}

/**
 * 处理字段为null
 * @param {object} dataObj 数据对象
 * @param {array} ignoreFields 忽略的字段
 * @param {any} except 替换null的
 */
export function handleFieldNull(
  dataObj: any,
  ignoreFields: any[] = [],
  except = "-"
) {
  if (!isObject(dataObj)) {
    return dataObj;
  }
  let res = deepCopy(dataObj);
  for (const key in res) {
    if (isArray(ignoreFields) && isString(key)) {
      if (ignoreFields.includes(key)) {
        continue;
      }
    }
    const item = res[key];
    if (isNull(item)) {
      res[key] = except;
    } else if (isString(item)) {
      item.toLowerCase() === "null" && (res[key] = except);
    }
  }
  return res;
}

// 公用表格BaseTable组件数据过滤器
export const formatResult = function (res: any) {
  const failRet = { list: res, total: 0 };
  if (!isObject(res)) {
    return failRet;
  }
  const { records, total, size, current } = res;
  if (!isArray(records)) {
    return failRet;
  }
  return {
    list: records.map((item: any, index: number) => ({
      ...item,
      index: index + 1 + current * size,
    })),
    total,
  };
};

/** 将驼峰命名转为 小写加下划线 命名  */
export function convertToUnderscore(str?: string) {
  if (!str) return "";
  return str.replace(/([a-z])([A-Z])/g, "$1_$2").toLowerCase();
}

export function setFavicon(href: string) {
  const icon = document.getElementById("Favicon") as HTMLLinkElement;
  if (href) {
    icon.href = `${MINIIO_PREVIEW_PATH}?filePath=${href}`;
  } else {
    icon.href = `${process.env.PUBLIC_URL + "favicon.ico"}`;
  }
}

/** 去除字符串前后空格和前后缀 */
export function removeFirstAndLast(
  str: string,
  option?: { start?: string; end?: string }
) {
  if (!str || typeof str !== "string") return str;
  let cleanedString = str?.trim() ?? "";
  if (option?.start && cleanedString.startsWith(option.start)) {
    cleanedString = cleanedString.slice(option.start.length);
  }
  if (option?.end && cleanedString.endsWith(option.end)) {
    cleanedString = cleanedString.slice(0, -option.end.length);
  }
  return cleanedString;
}

export function mergeJsonString(jsonStrings: string[]) {
  let merge = {};
  for (const it of jsonStrings) {
    try {
      const obj = JSON.parse(it);
      merge = { ...merge, ...obj };
    } catch (error) {}
  }
  return JSON.stringify(merge);
}

/**
 * 枚举转换成数组 去除值为数字时的反向映射
 * @param obj
 */
export function enumToOptions(obj: object): DefaultOptionType[] {
  return Object.entries(obj)
    .filter(([key]) => isNaN(Number(key)))
    .map(([label, value]: [string, string]) => {
      return {
        label,
        value,
      };
    });
}
